import { Box, Img } from '@chakra-ui/react';
import { When } from 'react-if';

import EllipseIcon from 'assets/icons/ellipse.svg';

export default function RowState(state: string | null): React.ReactElement {
  return (
    <When condition={state === 'new'}>
      <Box as='span'>
        <Img width='8px' height='8px' src={EllipseIcon} />
      </Box>
    </When>
  );
}
