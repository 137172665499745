import {
  Avatar, AvatarGroup, Box, Flex, Img, Text, Tooltip,
} from '@chakra-ui/react';
import { When } from 'react-if';

import { SamplyOverview } from './SamplyOverview';
import { transferServerApi } from 'api/transfersServerApi';
import DateIcon from 'assets/icons/date.svg';
import ExpiryIcon from 'assets/icons/expiry.svg';
import PhoneIcon from 'assets/icons/phone.svg';
import SettingsIcon from 'assets/icons/settings.svg';
import SizeIcon from 'assets/icons/size.svg';
import TeamIcon from 'assets/icons/team.svg';
import TransferIcon from 'assets/icons/transfer.svg';
import AuthorizeDisplay from 'components/AuthorizeDisplay';
import DateComponent from 'components/DateComponent';
import { InfoSet } from 'components/Infoset';
import Overview from 'components/Overview';
import QuerySuspense from 'components/QuerySuspense';
import { Roles } from 'contexts/user/claims';
import { useApiQuery } from 'hooks/useApiQuery';
import { filesSizeTags } from 'utils/formatter';

export function TransferOverview({ transferId }: { transferId?: string | number }): React.ReactElement {
  const overviewQuery = useApiQuery({
    queryKey: ['transfer-overview', transferId],
    queryFn: () => transferServerApi.GET('/api-v2/transfers/{transferId}/overview', {
      params: {
        path: {
          transferId: Number(transferId),
        },
      },
    }),
  });

  return (
    <Overview
      header='Transfer Overview'
      background='purple.1000'
      before={{
        background: 'purple.600',
      }}
    >
      <QuerySuspense queries={[overviewQuery.queryResult]} mountChildrenDiscrete>

        <InfoSet
          label='Transfer'
          icon={TransferIcon}
          value={(
            <Text as='span' whiteSpace='pre-wrap'>
              <Text as='span'>{overviewQuery.apiResult?.data?.id}: {overviewQuery.apiResult?.data?.name}</Text>
            </Text>
          )}
        />
        <AuthorizeDisplay role={Roles.StudioMember}>
          <InfoSet
            label='From'
            icon={TeamIcon}
            condition={!!overviewQuery.apiResult?.data?.from}
            value={(
              <Flex dir='row' gap='2'>
                <Tooltip label={`${overviewQuery.apiResult?.data?.from?.email} - ${overviewQuery.apiResult?.data?.from?.name ?? 'N/A'}`} hasArrow arrowSize={15}>
                  <Avatar size='sm' name={overviewQuery.apiResult?.data?.from?.name} borderColor='transparent' />
                </Tooltip>
              </Flex>
          )}
          />
        </AuthorizeDisplay>
        <InfoSet
          label='Recipients'
          icon={TeamIcon}
          condition={overviewQuery.apiResult?.data?.recipients?.length}
          value={(
            <Flex dir='row' gap='2'>
              <Tooltip label={overviewQuery.apiResult?.data?.recipients?.map((m) => m.name).join(', ')} hasArrow arrowSize={15}>
                <AvatarGroup size='sm' max={3}>
                  {overviewQuery.apiResult?.data?.recipients.map((recipient) => (
                    <Avatar name={recipient.name} borderColor='transparent' key={recipient.name} />
                  ))}
                </AvatarGroup>
              </Tooltip>
            </Flex>
          )}
        />
        <InfoSet
          label='Date'
          icon={DateIcon}
          value={overviewQuery.apiResult?.data?.date ? <DateComponent date={overviewQuery.apiResult?.data?.date} /> : undefined}
        />
        <InfoSet
          label='Expiry'
          icon={ExpiryIcon}
          value={<DateComponent date={overviewQuery.apiResult?.data?.expiresAt} />}
        />
        <InfoSet
          label='Files'
          icon={SizeIcon}
          value={filesSizeTags(overviewQuery.apiResult?.data?.stats.fileSize, overviewQuery.apiResult?.data?.stats.fileCount)}
        />
        <When condition={overviewQuery.apiResult?.data?.requiresMfa}>
          <InfoSet
            label='Settings'
            icon={SettingsIcon}
            condition={overviewQuery.apiResult?.data?.samplyUrl || overviewQuery.apiResult?.data?.requiresMfa}
            value={(
              <Flex gap='15px'>
                <When condition={overviewQuery.apiResult?.data?.requiresMfa}>
                  <Flex alignItems='center' gap='7px'>
                    <Img h='15px' w='17px' src={PhoneIcon} />
                    <Text whiteSpace='nowrap'>SMS Required</Text>
                  </Flex>
                </When>
              </Flex>
          )}
          />
        </When>
        <SamplyOverview transferOverview={overviewQuery.apiResult?.data} />
      </QuerySuspense>
    </Overview>
  );
}
