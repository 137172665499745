import {
  Flex, Stack, Switch, Text,
} from '@chakra-ui/react';
import {
  FieldError, FormState, Path, UseFormGetValues, UseFormRegister, ValidateResult,
} from 'react-hook-form';

import ErrorDisplay from 'components/error/ErrorDisplay';

export interface ISelectedContactRoles {
  isUploader: boolean;
  isReceiver: boolean;
}

export function RoleSelection<T extends ISelectedContactRoles>({
  register,
  defaults,
  getValues,
  formState,
}: {
  register: UseFormRegister<T>
  defaults?: ISelectedContactRoles,
  getValues: UseFormGetValues<T>
  formState: FormState<T>
}): React.ReactElement {
  const validateRolesSelection = (): ValidateResult => {
    const { isUploader, isReceiver } = getValues();
    if (!isUploader && !isReceiver) {
      return 'At least one role must be selected';
    }
    return true;
  };

  const { errors } = formState;

  return (
    <Stack gap='12px'>
      <Flex alignItems='center' background='#0F1118' padding='15px' borderRadius='10px'>
        <Stack>
          <Text fontSize='21px'>Uploader</Text>
          <Text fontSize='12px'>Uploaders will upload sources to Sterling</Text>
        </Stack>
        <Switch
          defaultChecked={defaults?.isUploader}
          size='lg'
          {...register('isUploader' as Path<T>)}
          ml='auto'

        />
      </Flex>
      <Flex alignItems='center' background='#0F1118' padding='12px' borderRadius='10px'>
        <Stack>
          <Text fontSize='21px'>Receiver</Text>
          <Text fontSize='12px'>Receivers will receive masters from Sterling</Text>
        </Stack>
        <Switch
          defaultChecked={defaults?.isReceiver}
          size='lg'
          {...register('isReceiver' as Path<T>, { validate: validateRolesSelection })}
          ml='auto'
        />
      </Flex>
      <ErrorDisplay error={errors.isReceiver as FieldError} />
    </Stack>
  );
}
