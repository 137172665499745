import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import {
  ReactNode, createContext, useContext, useMemo,
} from 'react';

interface IIPResponse {
  ipVersion: number;
  ipAddress: string;
  latitude: number;
  longitude: number;
  countryName: string;
  countryCode: string;
  timeZone: string;
  zipCode: string;
  cityName: string;
  regionName: string;
  isProxy: boolean;
  continent: string;
  continentCode: string;
  currency: Currency;
  language: string;
  timeZones?: (string)[] | null;
  tlds?: (string)[] | null;
}

interface Currency {
  code: string;
  name: string;
}

interface IGeoInfoState {
  userCountryCode?: string,
}

export const GeoInfoContext = createContext<IGeoInfoState>({
  userCountryCode: undefined,
});

export function GeoInfoProvider({ children }: { children: ReactNode }): React.ReactElement {
  const countryApi = useQuery({
    queryKey: ['https://freeipapi.com/api/json'],
    queryFn: () => axios.get<IIPResponse>('https://freeipapi.com/api/json'),
  });

  const state: IGeoInfoState = useMemo(() => ({
    userCountryCode: countryApi.data?.data?.countryCode,
  }), [countryApi.data?.data?.countryCode]);

  return (
    <GeoInfoContext.Provider value={state}>
      {children}
    </GeoInfoContext.Provider>
  );
}

export function useGeoInfo(): IGeoInfoState {
  return useContext(GeoInfoContext);
}
