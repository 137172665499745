import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useToast,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { Else, If, Then } from 'react-if';
import { Link, useSearchParams } from 'react-router-dom';

import { studioServerApi } from 'api/studioServerApi';
import ApproveConfirmIcon2 from 'assets/icons/approve-confirmation-black.svg';
import ApproveConfirmIcon from 'assets/icons/approve-confirmation.svg';
import pdfIcon from 'assets/icons/pdf.svg';
import QuerySuspense from 'components/QuerySuspense';
import { useApiQuery } from 'hooks/useApiQuery';
import useTenantContext from 'hooks/useTenantContext';

export default function ApproveProjectConfirmation(): React.ReactElement {
  const toast = useToast();
  const { logoSrc } = useTenantContext();

  const [searchParams] = useSearchParams();
  const approvalConfirmationIdentifier = searchParams.get('approval-confirmation-identifier');

  const confirmationQuery = useApiQuery({
    queryKey: [approvalConfirmationIdentifier],
    queryFn: () => studioServerApi.GET('/api/customer-portal-confirmations', {
      params: {
        query: { approvalConfirmationIdentifier: approvalConfirmationIdentifier! },
      },
    }),
    enabled: !!approvalConfirmationIdentifier,
  });

  async function handleApprove(): Promise<void> {
    try {
      await studioServerApi.POST('/api/customer-portal-confirmations/approve/{approvalConfirmationIdentifier}', {
        params: {
          path:
           { approvalConfirmationIdentifier: approvalConfirmationIdentifier! },
        },
        enabled: !!approvalConfirmationIdentifier,
      });

      toast({
        title: 'Confirmation Approved',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      await confirmationQuery.queryResult.refetch();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to approve the confirmation',
        status: 'error',
      });
      console.error(error);
    }
  }

  return (
    <QuerySuspense queries={[confirmationQuery.queryResult]}>
      <Flex
        padding='20px'
        direction='column'
        align='center'
        w='400px'
        mx='auto'
        mt='50px'
      >
        <Box width='400px' padding='20px'>
          <Flex direction='column' gap='25px'>
            <Flex justifyContent='center' mb='15px'>
              <Image src={logoSrc} width='300px' />
            </Flex>

            <Heading fontSize='24px' textAlign='center'>
              Project Confirmation
            </Heading>

            <Text>Hi {confirmationQuery.apiResult?.data?.Name},</Text>

            <Text>
              Confirmation #
              {confirmationQuery.apiResult?.data?.Confirmation?.ConfirmationNumber}
              {' '}
              is available.
              {' '}
              {' '}
            </Text>
            <Text>
              The total amount for the confirmation is:
              {' '}
              <strong>${confirmationQuery.apiResult?.data?.Confirmation?.Amount}</strong>
            </Text>

            <If condition={confirmationQuery.apiResult?.data?.ApprovedByContact}>
              <Then>
                <Flex alignItems='center'>
                  <Image src={ApproveConfirmIcon} alt='Approve Confirmation icon' mr='8px' />
                  <Text>
                    You approved this confirmation on
                    {' '}
                    {format(confirmationQuery.apiResult?.data?.MostRecentApprovalByContactDate || new Date(), 'PPPPpp')}
                    .
                  </Text>
                </Flex>
              </Then>
              <Else>
                <Button
                  colorScheme='yellow'
                  onClick={handleApprove}
                  leftIcon={<Image src={ApproveConfirmIcon2} alt='Approve confirmation icon' />}
                >
                  Approve Confirmation
                </Button>
              </Else>
            </If>

            <Button
              as={Link}
              to={confirmationQuery.apiResult?.data?.DownloadPdfUrl || '#'}
              target='_blank'
              color='white'
              backgroundColor='gray.600'
              textDecoration='none'
              _hover={{ textDecoration: 'none', color: 'white', backgroundColor: 'gray.700' }}
              leftIcon={<Image src={pdfIcon} alt='PDF icon' />}
            >
              View Confirmation
            </Button>
          </Flex>
        </Box>
      </Flex>
    </QuerySuspense>
  );
}
