import {
  Box, Button, Flex, Image, Link, Text,
} from '@chakra-ui/react';
import { useContext } from 'react';

import GoogleIcon from 'assets/icons/google.svg';
import MicrosoftIcon from 'assets/icons/microsoft.svg';
import { UserContext } from 'contexts/user/UserContextProvider';

const providerDetails: Record<string, { name: string; icon: string; link: string }> = {
  'microsoft.com': {
    name: 'Microsoft',
    icon: MicrosoftIcon,
    link: 'https://account.microsoft.com',
  },
  'google.com': {
    name: 'Google',
    icon: GoogleIcon,
    link: 'https://myaccount.google.com',
  },
};

export default function OauthUsersPage(): React.ReactElement {
  const { signInProvider } = useContext(UserContext);

  const provider = providerDetails[signInProvider!];

  return (
    <Box>
      <Flex alignItems='center' gap='10px' mb='10px'>
        <Image src={provider.icon} height='25px' alt={`${provider.name} Icon`} />
        <Text fontWeight='bold' fontSize='30px'>Security Settings</Text>
      </Flex>
      <Text mb='20px'>
        You are currently logged in with your
        {' '}
        {provider.name}
        {' '}
        account. To manage your security settings, visit
        {' '}
        <Link href={provider.link} fontWeight='bold' textDecoration='underline'>
          {provider.link}
        </Link>
        .
      </Text>
    </Box>
  );
}
