import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorPage from './ErrorPage';

interface CustomErrorBoundaryProps {
  fallbackRender?: (props: { error: Error }) => React.ReactNode;
  children: React.ReactNode;
}

export default function CustomErrorBoundary({
  fallbackRender,
  children,
}: CustomErrorBoundaryProps): React.ReactElement {
  return (
    <ErrorBoundary
      fallbackRender={
        fallbackRender
        // eslint-disable-next-line react/no-unstable-nested-components
        || (({ error }) => <ErrorPage error={error} />)
      }
    >
      {children}
    </ErrorBoundary>
  );
}
