import { Flex, Img } from '@chakra-ui/react';

import ArtistIcon from 'assets/icons/artist.svg';
import ClockIcon from 'assets/icons/clock-white.svg';
import DateIcon from 'assets/icons/date.svg';
import ExpiryIcon from 'assets/icons/expiry.svg';
import FilesIcon from 'assets/icons/file-header.svg';
import MixTypeIcon from 'assets/icons/mix-type.svg';
import NameIcon from 'assets/icons/name-icon.svg';
import FolderIcon from 'assets/icons/name.svg';
import ProjectIcon from 'assets/icons/project.svg';
import ReviewHeaderIcon from 'assets/icons/review-header.svg';
import ReviewIcon from 'assets/icons/review.svg';
import SizeIcon from 'assets/icons/size.svg';
import SongIcon from 'assets/icons/song.svg';
import TeamIcon from 'assets/icons/team.svg';
import TransferIcon from 'assets/icons/transfer.svg';
import ProfileIcon from 'assets/icons/user.svg';
import VersionIcon from 'assets/icons/version.svg';
import FileIcon from 'assets/icons/white-file-icon.svg';

export function IconWithLabel({ icon, label } : { icon: string, label: string }): React.ReactElement {
  return (
    <Flex>
      <Img color='white' height='20px' width='20px' src={icon} marginRight='7px' />
      {label}
    </Flex>
  );
}
export function FolderHeader(): React.ReactElement {
  return <IconWithLabel icon={FolderIcon} label='Folder' />;
}

export function NameHeader(): React.ReactElement {
  return <IconWithLabel icon={NameIcon} label='Name' />;
}

export function FromHeader(): React.ReactElement {
  return <IconWithLabel icon={ProfileIcon} label='From' />;
}

export function RestoredByHeader(): React.ReactElement {
  return <IconWithLabel icon={ProfileIcon} label='Restored by' />;
}

export function ArtistHeader(): React.ReactElement {
  return <IconWithLabel icon={ArtistIcon} label='Artist' />;
}

export function ProjectHeader(): React.ReactElement {
  return <IconWithLabel icon={ProjectIcon} label='Project' />;
}

export function ProjectNumberHeader(): React.ReactElement {
  return <IconWithLabel icon={ProjectIcon} label='Project No.' />;
}

export function DateHeader(): React.ReactElement {
  return <IconWithLabel icon={DateIcon} label='Date' />;
}

export function ReviewHeader(): React.ReactElement {
  return <IconWithLabel icon={ReviewIcon} label='Review' />;
}

export function SongHeader(): React.ReactElement {
  return <IconWithLabel icon={SongIcon} label='Song' />;
}

export function VersionHeader(): React.ReactElement {
  return <IconWithLabel icon={VersionIcon} label='Version' />;
}

export function SizeHeader(): React.ReactElement {
  return <IconWithLabel icon={SizeIcon} label='Size' />;
}

export function DateRestoredHeader(): React.ReactElement {
  return <IconWithLabel icon={DateIcon} label='Date Restored' />;
}
export function StatusHeader(): React.ReactElement {
  return <IconWithLabel icon={ReviewIcon} label='Status' />;
}

export function ExpiryHeader(): React.ReactElement {
  return <IconWithLabel icon={ExpiryIcon} label='Expiry' />;
}

export function TeamHeader(): React.ReactElement {
  return <IconWithLabel icon={TeamIcon} label='Team' />;
}

export function TransferHeader(): React.ReactElement {
  return <IconWithLabel icon={TransferIcon} label='Transfers' />;
}

export function TransferIDHeader(): React.ReactElement {
  return <IconWithLabel icon={TransferIcon} label='Transfer ID' />;
}

export function FilesHeader(): React.ReactElement {
  return <IconWithLabel icon={FilesIcon} label='Files' />;
}

export function FileHeader(): React.ReactElement {
  return <IconWithLabel icon={FileIcon} label='File' />;
}

export function RecipientsHeader(): React.ReactElement {
  return <IconWithLabel icon={TeamIcon} label='Recipients' />;
}

export function MixType(): React.ReactElement {
  return <IconWithLabel icon={MixTypeIcon} label='Mix Type' />;
}

export function Review(): React.ReactElement {
  return <IconWithLabel icon={ReviewHeaderIcon} label='Review' />;
}

export function Technical(): React.ReactElement {
  return <IconWithLabel icon={ProjectIcon} label='Technical' />;
}

export function Duration(): React.ReactElement {
  return <IconWithLabel icon={ClockIcon} label='Duration' />;
}

export function FileSize(): React.ReactElement {
  return <IconWithLabel icon={SizeIcon} label='File Size' />;
}

export function RecipientEmail(): React.ReactElement {
  return <IconWithLabel icon={ProfileIcon} label='Recipient' />;
}

export function EmailTemplateHeader(): React.ReactElement {
  return <IconWithLabel icon={VersionIcon} label='Email' />;
}

export function EmailStatusHeader(): React.ReactElement {
  return <IconWithLabel icon={ReviewIcon} label='Status' />;
}
