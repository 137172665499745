import {
  Box, Checkbox, Flex, Input, Stack,
  Text, Textarea,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useWizard } from 'react-use-wizard';

import ErrorDisplay from 'components/error/ErrorDisplay';
import AsyncButton from 'components/form/AsyncButton';
import LabelInput from 'components/form/LabelInput';
import { useBookingContext } from 'contexts/booking/BookingContext';

export type SessionDetailsFormValues = {
  artistName: string;
  sessionName: string;
  notes: string;
  terms: boolean;
};

export default function SessionDetails(): React.ReactElement {
  const {
    handleSubmit, control, formState, reset,
  } = useForm<SessionDetailsFormValues>();
  const { nextStep } = useWizard();
  const { sessionDetails, setSessionDetails } = useBookingContext();

  useEffect(() => {
    if (formState.isDirty || !sessionDetails) {
      return;
    }
    reset(sessionDetails);
  }, [formState.isDirty, sessionDetails, reset]);

  async function onSubmit(data: SessionDetailsFormValues): Promise<void> {
    setSessionDetails({
      sessionName: data.sessionName.trim(),
      notes: data.notes?.trim(),
      artistName: data.artistName.trim(),
      terms: data.terms,
    });
    await nextStep();
  }
  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <Box mb='25px' mt='15px'>
        <form>
          <Stack mb='15px'>
            <Flex direction='column' gap='15px'>
              <Controller
                name='artistName'
                control={control}
                rules={{ required: 'Artist name is required' }}
                render={({ field, fieldState }) => (
                  <LabelInput label='Artist name' error={fieldState.error}>
                    <Input
                      mt='7px'
                      {...field}
                      isInvalid={!!fieldState.error}
                      defaultValue={sessionDetails?.artistName}
                    />
                  </LabelInput>
                )}
              />
              <Controller
                name='sessionName'
                control={control}
                rules={{ required: 'Session name is required' }}
                render={({ field, fieldState }) => (
                  <LabelInput label='Session name' error={fieldState.error}>
                    <Input
                      mt='7px'
                      {...field}
                      isInvalid={!!fieldState.error}
                      defaultValue={sessionDetails?.sessionName}
                    />
                  </LabelInput>
                )}
              />
              <Controller
                name='notes'
                control={control}
                render={({ field, fieldState }) => (
                  <LabelInput label='Notes' error={fieldState.error}>
                    <Textarea
                      mt='7px'
                      {...field}
                      isInvalid={!!fieldState.error}
                      defaultValue={sessionDetails?.notes}
                    />
                  </LabelInput>
                )}
              />
            </Flex>
            <Text color='gold.1000' mt='15px' fontSize='20px'>CANCELLATION POLICY</Text>
            <Text>To cancel or re-schedule a session without penalty, please provide Sterling Sound at least four (4) business day&apos;s notice. </Text>
            <Text>All changes should be communicated via email. Our business hours are Monday to Friday 10:00AM to 6:00PM Eastern Time.
            </Text>
            <Text>Cancellations or re-scheduling with less than four (4) business days&apos;s notice will result in a 50% penalty.</Text>
            <Text>Cancellation or re-scheduling on the day of the session will result in a 100% penalty.</Text>
            <Controller
              name='terms'
              control={control}
              defaultValue={false}
              rules={{ validate: (value) => value === true || 'You must agree to the cancellation policy' }}
              render={({ field, fieldState }) => (
                <>
                  <Checkbox {...field} value=''>
                    I agree to Sterling Sound&apos;s
                    <b> cancellation</b>
                    {' '}
                    policy
                  </Checkbox>
                  <ErrorDisplay error={fieldState.error} />
                </>
              )}
            />
          </Stack>
          <AsyncButton
            formState={formState}
            type='submit'
            mt={4}
            width='100%'
            height='54'
            onClick={handleSubmit(onSubmit)}
          >Continue
          </AsyncButton>
        </form>
      </Box>
    </Flex>
  );
}
