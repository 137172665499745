import {
  Button, Flex, Heading, Img, Spacer, TabList, TabPanel, TabPanels, Tabs,
} from '@chakra-ui/react';
import { useState } from 'react';
import { When } from 'react-if';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFeatureGate } from 'statsig-react';

import ProjectFilesTable from './components/ProjectFilesTable';
import { ProjectOverview } from './components/ProjectOverview';
import ProjectContactsModal from '../projects-page/components/modal/ProjectContactsModal';
import ProjectIcon from 'assets/icons/project.svg';
import TeamIcon from 'assets/icons/team-black.svg';
import TransferStaticIcon from 'assets/icons/transfer.svg';
import UploadIcon from 'assets/icons/upload-black.svg';
import { NavigationTabs } from 'components/NavigationTabs';
import { FilesIcon } from 'components/tabs/FilesIcon';
import { TransferIcon } from 'components/tabs/TransferIcon';
import { TabWithIcon } from 'components/TabWithIcon';
import useUserContext from 'hooks/useUserContext';
import TransfersTable from 'pages/transfers/project-overview-page/components/TransfersTable';

type TabKeys = 'transfers' | 'files';

const tabMap: Record<TabKeys, number> = {
  transfers: 0,
  files: 1,
};

const invisibleColumns = {
  project: false,
  artist: false,
};

const emptyTableState = {
  message: 'You have no transfers for this project',
  icon: TransferStaticIcon,
};

export function ProjectOverviewPage(): React.ReactElement {
  const { projectId, tabValue } = useParams<{ tabValue: TabKeys, projectId: string }>();
  const navigate = useNavigate();

  const { isStudioMember } = useUserContext();

  const projectContactsModalEnabled = useFeatureGate('project_contacts_modal');

  const [isMembersModalOpen, setMembersModalOpen] = useState(false);
  const onMembersModalClose = (): void => setMembersModalOpen(false);

  return (
    <Flex direction='column' gap='50px'>
      <Flex justifyContent='center' gap='20px'>
        <Flex height='30px' align='center' gap='10px'>
          <NavigationTabs hideSearchBar tabName='projects' disableSelectedStyling />
          <ProjectContactsModal
            projectId={projectId!}
            isOpen={isMembersModalOpen}
            onClose={onMembersModalClose}
          />
        </Flex>
        <Spacer />
        <When condition={isStudioMember}>
          <When condition={projectContactsModalEnabled.gate.value}>
            <Button
              onClick={() => setMembersModalOpen(true)}
              leftIcon={<Img src={TeamIcon} w='20px' h='20px' />}
            >
              Project Contacts
            </Button>
          </When>
        </When>
        <Button
          onClick={() => void navigate(`/transfers/transfer-upload/${projectId}`)}
          leftIcon={<Img src={UploadIcon} w='20px' h='20px' />}
          padding='15px 25px'
        >
          Upload Transfer
        </Button>
      </Flex>
      <ProjectOverview projectId={projectId!} />
      <Tabs
        isLazy
        lazyBehavior='unmount'
        index={tabMap[tabValue!]}
      >
        <TabList>
          <RouterLink to={`/transfers/projects/${projectId}/transfers`}>
            <TabWithIcon
              icon={TransferIcon()}
              label='Transfers'
            />
          </RouterLink>
          <RouterLink to={`/transfers/projects/${projectId}/files`}>
            <TabWithIcon
              icon={FilesIcon()}
              label='Files'
            />
          </RouterLink>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TransfersTable path={`/file-api/projects/${projectId}/transfers`} invisibleColumns={invisibleColumns} emptyTableState={emptyTableState} />
          </TabPanel>
          <TabPanel>
            <ProjectFilesTable
              projectId={projectId!}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
