import {
  Box,
  Button,
  Flex,
  Image,
  ModalContentProps,
  Text,
} from '@chakra-ui/react';

import { BasicModalHeader, ModalFrame } from './modal-frame/ModalFrame';
import ChromeIcon from '../assets/icons/chrome.svg';
import CloseIcon from '../assets/icons/close.svg';
import DownloadIcon from '../assets/icons/download.svg';

const content: ModalContentProps = {
  maxW: '712px',
  minW: '720px',
  marginTop: 200,
};

export interface IChromeDownloadModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ChromeDownloadModal({
  isOpen, onClose,
}: IChromeDownloadModalProps): React.ReactElement {
  async function handleClose(): Promise<void> {
    onClose();
  }

  return (
    <ModalFrame
      header={<BasicModalHeader icon={DownloadIcon} title='Download Requires Chrome' />}
      isOpen={isOpen}
      onClose={handleClose}
      style={{ content }}
    >
      <Flex
        direction='column'
        gap='8px'
      >
        <Box backgroundColor='gray.900' padding='25px' borderRadius='10px'>
          <Flex flexDirection='row' gap='40px' alignItems='center'>
            <Flex flexDirection='column' width='400px'>
              <Text fontWeight='600' fontSize='18px' marginBottom='10px'>Download Chrome</Text>
              <Text textAlign='left' marginBottom='15px'>To proceed with any downloads, you must use Google Chrome.</Text>
            </Flex>
            <Image src={ChromeIcon} width='80px' height='80px' marginLeft='25px' />
          </Flex>
        </Box>
      </Flex>
      <Image src={CloseIcon} alt='Close' position='absolute' top='25px' right='25px' onClick={handleClose} style={{ cursor: 'pointer' }} />
      <Button marginTop='15px' as='a' href='https://www.google.com/chrome/' fontWeight='600' target='_blank' rel='noopener noreferrer' width='38%' height='50px'>
        <Image src={ChromeIcon} marginRight='5px' />
        Download Chrome
      </Button>
    </ModalFrame>
  );
}
