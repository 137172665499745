import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';

export function ChakraAlert({ title, description, alertStatus }: { title: string, description: string, alertStatus: 'success' | 'warning' | 'error' }): React.ReactElement {
  return (
    <Alert
      status={alertStatus}
      variant='subtle'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
      height='300px'
      borderRadius='5px'
    >
      <AlertIcon boxSize='40px' mr={0} />
      <AlertTitle mt={4} mb={2} fontSize='xl'>
        {title}
      </AlertTitle>
      <AlertDescription maxWidth='md'>
        {description}
      </AlertDescription>
    </Alert>
  );
}
