import {
  Tab, TabList, TabPanel, TabPanels, Tabs,
} from '@chakra-ui/react';
import {
  useState,
} from 'react';

import useUserContext from 'hooks/useUserContext';
import { FileTable, TransfersTableColumns } from 'pages/transfers/files-page/components/FileTable';

type ProjectFileTableProps = {
  projectId: string,
  invisibleColumns?: TransfersTableColumns,
};

export default function ProjectFilesTable({
  projectId,
  invisibleColumns,
} : ProjectFileTableProps): React.ReactElement {
  const [selectedFiles, setSelectedFiles] = useState<{ FileId: number, FileName: string }[]>([]);
  const { isStudioMember } = useUserContext();

  return (
    <Tabs defaultIndex={0} variant='soft-rounded'>
      <TabList>
        <Tab>From Sterling</Tab>
        <Tab>To Sterling</Tab>
      </TabList>
      <TabPanels>
        <TabPanel p='0px'>
          <FileTable
            apiPath={`/file-api/projects/${projectId}/files/from-studio`}
            invisibleColumns={{ ...invisibleColumns, CreatedBy: isStudioMember }}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            projectNumber={Number(projectId)}
            createdByFilter
          />
        </TabPanel>
        <TabPanel p='0px'>
          <FileTable
            apiPath={`/file-api/projects/${projectId}/files/to-studio`}
            invisibleColumns={invisibleColumns}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            projectNumber={Number(projectId)}
            createdByFilter
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
