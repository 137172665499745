import {
  Box, Button, FormLabel, Switch,
} from '@chakra-ui/react';
import { Table } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router';

import { ControlledSelect } from './ControlledSelect';
import { studioServerApi } from 'api/studioServerApi';
import { useApiQuery } from 'hooks/useApiQuery';

export function InvoiceFilters({ table, tableData }: { table: Table<any>, tableData: { ClientName?: string | null }[] }): React.ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedCountry, setSelectedCountry] = useState(searchParams.get('country') || null);
  const [selectedResource, setSelectedResource] = useState(searchParams.get('resource') || null);
  const [selectedClient, setSelectedClient] = useState(searchParams.get('client') || null);
  const [onlyOverdue, setOnlyOverdue] = useState(searchParams.get('overdue') === 'true' ? true : null);
  const [projectCreditLimit, setProjectCreditLimit] = useState(searchParams.get('creditLimit') === 'true' ? true : null);
  const [majorLabel, setMajorLabel] = useState(searchParams.get('majorLabel') === 'true' ? true : null);

  const countriesQuery = useApiQuery({
    queryKey: ['/api/countries'],
    queryFn: () => studioServerApi.GET('/api/countries'),
  });

  const resourceQuery = useApiQuery({
    queryKey: ['/api/resources'],
    queryFn: () => studioServerApi.GET('/api/resources/bookable'),
  });

  useEffect(() => {
    table.setColumnFilters([
      ...selectedCountry ? [{ id: 'Country', value: selectedCountry }] : [],
      ...selectedResource ? [{ id: 'ResourceIdentifier', value: selectedResource }] : [],
      ...onlyOverdue ? [{ id: 'Overdue', value: onlyOverdue }] : [],
      ...projectCreditLimit ? [{ id: 'HasProjectCredit', value: projectCreditLimit }] : [],
      ...majorLabel !== null ? [{ id: 'IsMajorLabel', value: majorLabel }] : [],
      ...selectedClient ? [{ id: 'ClientName', value: selectedClient }] : [],
    ]);

    const params = new URLSearchParams(searchParams);

    if (selectedCountry) {
      params.set('country', selectedCountry);
    } else {
      params.delete('country');
    }

    if (selectedClient) {
      params.set('client', selectedClient);
    } else {
      params.delete('client');
    }

    if (selectedResource) {
      params.set('resource', selectedResource);
    } else {
      params.delete('resource');
    }

    if (onlyOverdue) {
      params.set('overdue', 'true');
    } else {
      params.delete('overdue');
    }

    if (projectCreditLimit) {
      params.set('creditLimit', 'true');
    } else {
      params.delete('creditLimit');
    }

    if (majorLabel) {
      params.set('majorLabel', `${majorLabel}`);
    } else {
      params.delete('majorLabel');
    }

    setSearchParams(params);
  }, [
    selectedCountry,
    selectedResource,
    selectedClient,
    projectCreditLimit,
    majorLabel,
    onlyOverdue,
    table,
    setSearchParams,
    searchParams,
  ]);

  function resetFilters(): void {
    setSelectedCountry(null);
    setSelectedResource(null);
    setOnlyOverdue(null);
    setProjectCreditLimit(null);
    setMajorLabel(null);
    setSelectedClient(null);
  }

  return (
    <Box display='flex' w='1400px' mt='30px'>
      <Box display='flex' flexDir='column' mr='30px' w='100%'>
        <FormLabel> Country: </FormLabel>
        <ControlledSelect
          value={selectedCountry}
          onChange={(option) => setSelectedCountry(option)}
          options={[
            { label: 'All Countries', value: null },
            ...countriesQuery.apiResult?.data?.map((c: any) => ({
              label: c.Name!,
              value: c.Abbreviation!,
            })) ?? [],
          ]}
          placeHolder='Country'
          isSearchable
        />
      </Box>
      <Box display='flex' flexDir='column' w='100%' mr='30px'>
        <FormLabel> Resource: </FormLabel>
        <ControlledSelect
          value={selectedResource}
          onChange={(option) => setSelectedResource(option)}
          options={[
            { label: 'All Resources', value: null },
            ...resourceQuery.apiResult?.data?.map((r) => ({
              label: r.Name!,
              value: r.ResourceIdentifier!,
            })) ?? [],
          ]}
          placeHolder='Resource'
        />
      </Box>
      <Box display='flex' flexDir='column' w='100%' mr='30px'>
        <FormLabel> Client: </FormLabel>
        <ControlledSelect
          value={selectedClient}
          onChange={(option) => setSelectedClient(option)}
          options={[
            { label: 'All Clients', value: null },
            ...tableData.map((td) => ({
              label: td.ClientName!,
              value: td.ClientName!,
            })) ?? [],
          ]}
          placeHolder='Resource'
        />
      </Box>
      <Box display='flex' flexDir='column' w='100%'>
        <FormLabel> Label Type: </FormLabel>
        <ControlledSelect
          value={majorLabel === null ? null : majorLabel ? 'MajorLabel' : 'IndependentLabel'}
          onChange={(option) => {
            const newValue = option === 'MajorLabel' ? true
              : option === 'IndependentLabel' ? false
                : null;
            setMajorLabel(newValue);
          }}
          options={[
            { label: 'All Labels', value: null },
            { label: 'Major Labels', value: 'MajorLabel' },
            { label: 'Independent Labels', value: 'IndependentLabel' },
          ]}
          placeHolder='Label Type'
        />
      </Box>

      <Box display='flex' width='700px' mt='30px' mb='30px' ml='30px'>
        <Box display='flex' flexDir='column' alignItems='center' mr='30px' w='120px'>
          <FormLabel>Only Overdue</FormLabel>
          <Switch
            colorScheme='orange'
            size='lg'
            isChecked={onlyOverdue ?? false}
            onChange={(e) => setOnlyOverdue(e.target.checked ?? null)}
          />
        </Box>
        <Box display='flex' flexDir='column' alignItems='center' mr='30px' w='120px'>
          <FormLabel>Has Project Credit</FormLabel>
          <Switch
            colorScheme='orange'
            size='lg'
            isChecked={projectCreditLimit ?? false}
            onChange={(e) => setProjectCreditLimit(e.target.checked)}
          />
        </Box>
        <Button w='120px' onClick={() => resetFilters()}>Clear Filters</Button>
      </Box>
    </Box>
  );
}
