import { Box, Img, Text } from '@chakra-ui/react';
import {
  addDays,
  differenceInCalendarDays,
  formatDistanceStrict,
} from 'date-fns';

import NotDueIcon from 'assets/icons/invoice-status-icons/not-due.svg';
import OverdueLongTermIcon from 'assets/icons/invoice-status-icons/overdue-longterm.svg';
import OverdueShorttermIcon from 'assets/icons/invoice-status-icons/overdue-shortterm.svg';

function IconNotDue(): React.ReactElement {
  return <Img src={NotDueIcon} />;
}
function IconOverdueShortTerm(): React.ReactElement {
  return <Img src={OverdueShorttermIcon} />;
}
function IconOverdueLongTerm(): React.ReactElement {
  return <Img src={OverdueLongTermIcon} />;
}

export enum InvoiceStatus {
  NOT_DUE = 'notDue',
  OVERDUE_SHORT_TERM = 'overdueShortTerm',
  OVERDUE_LONG_TERM = 'overdueLongTerm',
}

interface StatusMessage {
  status: InvoiceStatus;
  message: string;
}

export function getStatusMessage(invoiceDate: string, netDays: 30 | 60): StatusMessage {
  const dueDate = addDays(new Date(invoiceDate), netDays);
  const today = new Date();

  const daysDifference = differenceInCalendarDays(today, dueDate);

  if (daysDifference < 0) {
    return {
      status: InvoiceStatus.NOT_DUE,
      message: `${formatDistanceStrict(today, dueDate)} until due`,
    };
  } if (daysDifference <= 30) {
    return {
      status: InvoiceStatus.OVERDUE_SHORT_TERM,
      message: `${formatDistanceStrict(dueDate, today)} overdue`,
    };
  }

  return {
    status: InvoiceStatus.OVERDUE_LONG_TERM,
    message: `${formatDistanceStrict(dueDate, today)} overdue`,
  };
}

export function InvoiceStatusDisplay({ invoiceDate, netDays }: { invoiceDate: string, netDays: 30 | 60 }): React.ReactElement {
  const { status, message } = getStatusMessage(invoiceDate, netDays);

  const statusConfig = {
    [InvoiceStatus.NOT_DUE]: {
      color: 'white',
      icon: <IconNotDue />,
    },
    [InvoiceStatus.OVERDUE_SHORT_TERM]: {
      color: 'white',
      icon: <IconOverdueShortTerm />,
    },
    [InvoiceStatus.OVERDUE_LONG_TERM]: {
      color: 'red',
      icon: <IconOverdueLongTerm />,
    },
  };

  const { color, icon } = statusConfig[status];

  return (
    <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <Box>{icon}</Box>
      <Text style={{ color }}>{message}</Text>
    </Box>
  );
}
