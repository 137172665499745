export function ProjectsIcon(): React.ReactElement {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 20 21'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        // eslint-disable-next-line max-len
        d='M9.27026 0.795562C10.0813 0.401479 11.0359 0.401479 11.8469 0.795562L18.9743 4.25888C20.7437 5.11866 20.7437 7.57257 18.9743 8.43241L11.8469 11.8956C11.0359 12.2897 10.0813 12.2897 9.27026 11.8956L2.14297 8.43241C0.373568 7.57257 0.373568 5.11866 2.14297 4.25888L9.27026 0.795562ZM10.9881 2.46495C10.7177 2.33359 10.3995 2.33359 10.1292 2.46495L3.00185 5.92827C2.64797 6.10023 2.64797 6.59101 3.00185 6.76297L10.1292 10.2262C10.3995 10.3577 10.7177 10.3577 10.9881 10.2262L18.1154 6.76297C18.4693 6.59101 18.4692 6.10023 18.1154 5.92827L10.9881 2.46495Z'
        fill='currentColor'
      />
      <path
        // eslint-disable-next-line max-len
        d='M0.918989 14.9357C1.15962 14.4545 1.74484 14.2594 2.22611 14.5001L10.1232 18.4485C10.3974 18.5857 10.7203 18.5857 10.9945 18.4485L18.8916 14.5001C19.3728 14.2594 19.958 14.4545 20.1987 14.9357C20.4393 15.4169 20.2443 16.0022 19.763 16.2428L11.8659 20.1913C11.0431 20.6028 10.0745 20.6028 9.25168 20.1913L1.35469 16.2428C0.873422 16.0022 0.678354 15.4169 0.918989 14.9357Z'
        fill='currentColor'
      />
      <path
        // eslint-disable-next-line max-len
        d='M2.22611 10.3458C1.74484 10.1051 1.15962 10.3002 0.918989 10.7815C0.678354 11.2627 0.873423 11.848 1.35469 12.0885L9.25175 16.0371C10.0745 16.4485 11.0431 16.4485 11.8659 16.0371L19.7629 12.0885C20.2442 11.848 20.4393 11.2627 20.1986 10.7815C19.958 10.3002 19.3729 10.1051 18.8916 10.3458L10.9945 14.2943C10.7203 14.4314 10.3974 14.4314 10.1231 14.2943L2.22611 10.3458Z'
        fill='currentColor'
      />
    </svg>
  );
}
