import { Navigate, Route } from 'react-router';

import PrivateRoute from '../components/PrivateRoute';
import { TransferPage } from '../pages/transfers/files-page/TransferPage';
import { ProjectOverviewPage } from '../pages/transfers/project-overview-page/ProjectOverviewPage';
import { ProjectsPage } from '../pages/transfers/projects-page/ProjectsPage';
import { UploadTransferPage } from '../pages/transfers/upload-transfer-pages/UploadTransferPage';
import { UploadTransferRequestPage } from '../pages/transfers/upload-transfer-pages/UploadTransferRequestPage';
import PageTemplate from 'components/page-template/PageTemplate';
import ArchivesPage from 'pages/archives/archives-page/ArchivesPage';
import ArchiveFilesPage from 'pages/archives/archives-page/components/ArchiveFilesPage';
import SubscriptionsPage from 'pages/Subscriptions/SubscriptionsPage';

export default function useTransfersRoutes(): React.ReactElement {
  return (
    <>
      <Route
        path='/transfers/*'
        element={(
          <PageTemplate>
            <PrivateRoute allowContact />
          </PageTemplate>
        )}
      >
        <Route
          path=''
          element={<Navigate to='/transfers/inbox' />}
        />
        <Route
          path='inbox'
          element={<ProjectsPage tabName='inbox' />}
        />
        <Route
          path='sent'
          element={<ProjectsPage tabName='sent' />}
        />
        <Route
          path='requests'
          element={<ProjectsPage tabName='requests' />}
        />
        <Route
          path='projects'
          element={<ProjectsPage tabName='projects' />}
        />
        <Route
          path='projects/:projectId/:tabValue'
          element={<ProjectOverviewPage />}
        />
        <Route
          path='transfers/:transferId'
          element={<TransferPage />}
        />
        <Route
          path='transfer-request/:transferRequestId'
          element={<UploadTransferRequestPage />}
        />
        <Route
          path='transfer-upload/:projectId'
          element={<UploadTransferPage />}
        />
        <Route
          path='subscriptions'
          element={<SubscriptionsPage />}
        />
      </Route>
      <Route
        path='/archives/*'
        element={(
          <PageTemplate>
            <PrivateRoute allowContact />
          </PageTemplate>
        )}
      >
        <Route
          path=''
          element={<ArchivesPage tabName='list' />}
        />
        <Route
          path='upload'
          element={<ArchivesPage tabName='upload' />}
        />
        <Route
          path=':archiveId'
          element={<ArchiveFilesPage />}
        />
      </Route>
      <Route
        path='/ct/*'
        element={<Navigate to={window.location.pathname.replace('/ct/', '/transfers/')} />}
      />
    </>
  );
}
