import {
  Button, Flex, FormControl, HStack, Img, Stack, Text, useToast,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FormProvider, useForm } from 'react-hook-form';
import { When } from 'react-if';
import { useParams } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';

import { ISelectedContactRoles, RoleSelection } from './components/RoleSelection';
import useCreateProjectContact from './hooks/useCreateProjectContact';
import ContactCard from '../components/ContactCard';
import { ProjectCard } from '../components/ProjectCard';
import { studioServerApi } from 'api/studioServerApi';
import AddUser from 'assets/icons/add-user.svg';
import FileIcon from 'assets/icons/white-file-icon.svg';
import AsyncButton from 'components/form/AsyncButton';
import { ModalFrame } from 'components/modal-frame/ModalFrame';
import QuerySuspense from 'components/QuerySuspense';
import { useApiQuery } from 'hooks/useApiQuery';
import { MySterlingNavigationTabs } from 'pages/booking/components/MySterlingNavigationTabs';
import { getDate } from 'utils/formatter';

export default function ProjectOverview(): React.ReactElement {
  const [searchParams] = useSearchParams();
  const isProjectCompleted = searchParams.get('isCompleted') === 'true';
  const { projectIdentifier } = useParams<{ projectIdentifier: string }>();
  const [isAddProjectContactModalOpen, setIsAddProjectContactModalOpen] = useState(false);
  const [selectedContactIdentifier, setSelectedContactIdentifier] = useState<string | null>(null);
  const formMethods = useForm<ISelectedContactRoles>();
  const {
    register, handleSubmit, formState, getValues,
  } = formMethods;
  const { addProjectContact, addRolesToContact } = useCreateProjectContact({ projectIdentifier: projectIdentifier! });
  const toast = useToast();

  const sessionResourcesQuery = useApiQuery({
    queryKey: ['/api/projects/{projectIdentifier}/session-resources/display', projectIdentifier],
    queryFn: () => studioServerApi.GET('/api/projects/{projectIdentifier}/session-resources/display', {
      params: {
        path: { projectIdentifier: projectIdentifier! },
      },
    }),
  });

  const contactsQuery = useApiQuery({
    queryKey: ['/api/projects/{projectIdentifier}/contacts', projectIdentifier],
    queryFn: () => studioServerApi.GET('/api/projects/{projectIdentifier}/project-contacts-with-roles', {
      params: {
        path: { projectIdentifier: projectIdentifier! },
      },
    }),
  });

  const recentContactsQuery = useApiQuery({
    queryKey: ['/api/contacts/my-contacts'],
    queryFn: () => studioServerApi.GET('/api/contacts/my-contacts'),
  });

  const filteredRecentContacts = useMemo(() => {
    const projectContacts = contactsQuery.apiResult?.data ?? [];
    const recentContacts = recentContactsQuery.apiResult?.data ?? [];

    return recentContacts.filter((contact) => !projectContacts.some((projectContact) => projectContact.Contact?.ContactIdentifier === contact.ContactIdentifier));
  }, [contactsQuery.apiResult?.data, recentContactsQuery.apiResult?.data]);

  const onSubmit = async (input: ISelectedContactRoles): Promise<void> => {
    await addProjectContact(selectedContactIdentifier!);
    await addRolesToContact(selectedContactIdentifier!, input);
    onCloseAddProjectContactModal();

    toast({
      title: 'Contact added to Project',
      status: 'success',
    });
  };

  const onOpenAddProjectContactModal = (contactIdentifier: string): void => {
    setSelectedContactIdentifier(contactIdentifier);
    setIsAddProjectContactModalOpen(true);
  };

  const onCloseAddProjectContactModal = (): void => {
    setSelectedContactIdentifier(null);
    setIsAddProjectContactModalOpen(false);
  };

  const projectQuery = useApiQuery({
    queryKey: ['/api/projects/display/{projectIdentifier}', projectIdentifier],
    queryFn: () => studioServerApi.GET('/api/projects/display/{projectIdentifier}', {
      params: {
        path: { projectIdentifier: projectIdentifier! },
      },
    }),
  });

  const resourceIdentifier = sessionResourcesQuery.apiResult?.data?.[0]?.ResourceIdentifier;

  const resourcesQuery = useApiQuery({
    queryKey: ['/api/resources/{resourceIdentifier}/display', resourceIdentifier],
    queryFn: () => studioServerApi.GET('/api/resources/{resourceIdentifier}/display', {
      params: {
        path: { resourceIdentifier: resourceIdentifier! },
      },
    }),
    enabled: !!resourceIdentifier,
  });

  return (
    <Flex justifyContent='center' alignItems='flex-start'>
      <Flex justifyContent='center' alignItems='flex-start'>
        <Flex direction='column' gap='40px'>
          <MySterlingNavigationTabs tabName='sessions' />
          <QuerySuspense queries={[sessionResourcesQuery.queryResult, contactsQuery.queryResult, recentContactsQuery.queryResult]}>
            <Stack spacing='30px'>
              <Stack spacing='20px'>
                {sessionResourcesQuery.apiResult?.data?.map((resource) => (
                  <ProjectCard
                    key={resource.ProjectIdentifier}
                    projectIdentifier={resource.ProjectIdentifier!}
                    resourceName={resource.ResourceName ?? 'N/A'}
                    resourceImageUrl={resource.ResourceSmallImageUrl ?? ''}
                    resourceManagerEmail={
                      contactsQuery.apiResult?.data?.find((contact) => contact.ProjectContactRoles?.find((roles) => roles === 'ResourceManager'))?.Contact?.Email ?? ''
                    }
                    resourceTitle={resourcesQuery.apiResult?.data?.OnlineTitle ?? ''}
                    // resourceTitle=''
                    scheduledDate={getDate(resource.ScheduledDate)}
                    artistName={resource.ArtistName ?? 'N/A'}
                    projectName={resource.ProjectName ?? 'N/A'}
                  />
                ))}

                <When condition={!isProjectCompleted}>
                  <HStack mt='20px' w='100%' spacing='20px'>
                    <Button as={Link} to={`/booking/sessions/${projectIdentifier}/add-contact`} flex='1'>
                      Add Uploader
                    </Button>
                    <Button as={Link} to={`/booking/sessions/${projectIdentifier}/add-contact`} flex='1' variant='outline' height='54px'>
                      Add Receiver
                    </Button>
                  </HStack>
                  <Button
                    isDisabled={isMobile}
                    {...(!isMobile && {
                      to: `/transfers/transfer-upload/${projectQuery.apiResult?.data?.ProjectNumber!}`,
                      target: '_blank',
                      as: Link,
                    })}
                    flex='1'
                    minHeight='54px'
                    variant='outline'
                    rel='noopener noreferrer'
                  >
                    <Img src={FileIcon} boxSize='20px' />
                    <Text ml='10px'>{isMobile ? 'Upload Files (Desktop Only)' : 'Upload Files'}</Text>
                  </Button>
                </When>
              </Stack>
              <Stack spacing='20px'>
                {contactsQuery.apiResult?.data?.filter((contact) => contact.ProjectContactRoles?.find((role) => role !== 'ResourceManager')).map((contact) => (
                  <ContactCard
                    key={contact.Contact?.ContactIdentifier}
                    firstName={contact.Contact?.FirstName ?? ''}
                    lastName={contact.Contact?.LastName ?? ''}
                    identifier={contact.Contact?.ContactIdentifier!}
                    email={contact.Contact?.Email ?? 'N/A'}
                    roles={contact.ProjectContactRoles ?? []}
                  />
                ))}
              </Stack>

              <When condition={!isProjectCompleted}>
                <Flex gap='10px' alignItems='center'>
                  <Img src={AddUser} w='25px' h='15px' />
                  <Text fontSize='20px' fontWeight='700'>Recent Contacts</Text>
                </Flex>
                <Stack spacing='20px'>
                  {filteredRecentContacts.map((contact) => (
                    <ContactCard
                      key={contact.ContactIdentifier}
                      firstName={contact.FirstName ?? ''}
                      lastName={contact.LastName ?? ''}
                      identifier={contact.ContactIdentifier!}
                      email={contact.Email ?? 'N/A'}
                      onAdd={onOpenAddProjectContactModal}
                    />
                  ))}
                </Stack>
              </When>
            </Stack>

            <ModalFrame
              isOpen={isAddProjectContactModalOpen}
              onClose={onCloseAddProjectContactModal}
              header={<Text fontSize='18px'>Select Role(s)</Text>}
            >
              <FormControl>
                <FormProvider {...formMethods}>
                  <form>
                    <RoleSelection register={register} formState={formState} getValues={getValues} />
                    <Stack spacing='10px' mt='20px'>
                      <AsyncButton formState={formState} onClick={handleSubmit(onSubmit)}>
                        Add to session
                      </AsyncButton>
                      <Button variant='link' onClick={onCloseAddProjectContactModal}>
                        Cancel
                      </Button>
                    </Stack>
                  </form>
                </FormProvider>
              </FormControl>
            </ModalFrame>
          </QuerySuspense>
        </Flex>
      </Flex>
    </Flex>
  );
}
