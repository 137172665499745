import { Tooltip } from '@chakra-ui/react';

export default function ProjectName(project: { externalSourceId: string | null, name: string | null }): React.ReactElement {
  if (!project.externalSourceId && !project.name) return <>N/A</>;
  return (
    <Tooltip label={project.externalSourceId} placement='auto'>
      {project.name || project.externalSourceId}
    </Tooltip>
  );
}
