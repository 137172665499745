import {
  Box, Button, Flex, Input, Stack, Text,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { OptionBase } from 'chakra-react-select';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import { omit } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useWizard } from 'react-use-wizard';

import { logIn } from 'api/auth';
import { studioServerApi } from 'api/studioServerApi';
import { Contact } from 'api/studioServerTypes';
import Email from 'assets/icons/email2.svg';
import FullName from 'assets/icons/fullname.svg';
import Password from 'assets/icons/lock2.svg';
import AsyncButton from 'components/form/AsyncButton';
import CountrySelector from 'components/form/CountrySelector';
import { IconInput } from 'components/form/IconInput';
import PhoneInput from 'components/form/PhoneInput';
import { validateEmail, validatePhoneNumber } from 'components/form/validators';
import QuerySuspense from 'components/QuerySuspense';
import { useGeoInfo } from 'contexts/GeoInfoProvider';
import { useApiQuery } from 'hooks/useApiQuery';
import useTenantContext from 'hooks/useTenantContext';

interface Country extends OptionBase {
  label: string;
  value: string;
}

type SignUpFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  country: Country;
  password: string;
  confirmPassword: string;
};

export default function RegisterStep(): React.ReactElement {
  const { logoSrc } = useTenantContext();
  const countriesQuery = useApiQuery({ queryKey: ['/api/countries'], queryFn: () => studioServerApi.GET('/api/countries') });
  const { userCountryCode } = useGeoInfo();
  const { nextStep } = useWizard();
  const defaultCountryOption = useMemo(() => {
    const defaultCountry = countriesQuery.apiResult?.data?.find((country: any) => country.Abbreviation === userCountryCode);
    return defaultCountry ? { label: defaultCountry.Name!, value: defaultCountry.Abbreviation! } : undefined;
  }, [countriesQuery.apiResult?.data, userCountryCode]);

  const {
    handleSubmit, control, reset, formState,
  } = useForm<SignUpFormValues>({
    defaultValues: {
      country: defaultCountryOption,
    },
  });

  useEffect(() => {
    reset({ country: defaultCountryOption });
  }, [defaultCountryOption, reset]);

  const { mutateAsync } = useMutation({
    mutationFn: (newContact: Contact & { email: string, password: string }) => studioServerApi.POST('/api/booking/register/contact', {
      body: {
        ...newContact,
        email: newContact.email.trim().toLocaleLowerCase(),
      },
    }),
    onSuccess: async (response, variables) => {
      await logIn(variables.email.trim().toLocaleLowerCase(), variables.password);

      const auth = getAuth();
      const user = auth.currentUser;
      await sendEmailVerification(user!, {
        url: `${window.location.origin}/booking/sign-up`,
      });

      await nextStep();
    },
  });

  async function onSubmit(data: SignUpFormValues): Promise<void> {
    const { email, password, country } = data;

    await mutateAsync({
      ...omit(data, ['country']), email: email.trim().toLocaleLowerCase(), password, Country: country.value,
    });
  }

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
    >
      <QuerySuspense queries={[countriesQuery.queryResult]}>
        <Box width='350px'>
          <form>
            <Flex
              justifyContent='center'
              mb='65px'
            >
              <img
                src={logoSrc}
                alt='Logo'
              />
            </Flex>
            <Text textAlign='center' fontSize='xl' fontWeight='bold' mb='35px'>Register</Text>
            <Stack spacing={4}>
              <Controller
                name='firstName'
                control={control}
                rules={{ required: 'First name is required' }}
                render={({ field, fieldState }) => (
                  <IconInput
                    error={fieldState.error}
                    icon={FullName}
                    alt='User First Name'
                  >
                    <Input {...field} placeholder='First name' isInvalid={!!fieldState.error} />
                  </IconInput>
                )}
              />
              <Controller
                name='lastName'
                control={control}
                rules={{ required: 'Last name is required' }}
                render={({ field, fieldState }) => (
                  <IconInput
                    error={fieldState.error}
                    icon={FullName}
                    alt='User Last Name'
                  >
                    <Input {...field} placeholder='Last name' isInvalid={!!fieldState.error} />
                  </IconInput>

                )}
              />
              <Controller
                name='email'
                control={control}
                rules={{ required: 'Email is required', validate: validateEmail }}
                render={({ field, fieldState }) => (
                  <IconInput
                    error={fieldState.error}
                    icon={Email}
                    alt='User Email'
                  >
                    <Input {...field} placeholder='Email' type='email' isInvalid={!!fieldState.error} />
                  </IconInput>

                )}
              />
              <Controller
                name='phoneNumber'
                control={control}
                rules={{ required: 'Phone is required', validate: validatePhoneNumber }}
                render={({ field, fieldState }) => <PhoneInput field={field} fieldState={fieldState} />}
              />
              <Controller
                name='country'
                control={control}
                rules={{ required: 'Country is required' }}
                render={({ field, fieldState }) => (
                  <CountrySelector
                    {...field}
                    error={fieldState.error}
                    options={countriesQuery.apiResult?.data?.map((country: any) => ({ label: country.Name!, value: country.Abbreviation! }))}
                    isSearchable
                  />
                )}
              />
              <Controller
                name='password'
                control={control}
                rules={{ required: 'Password is required', minLength: { value: 6, message: 'Password must be at least 6 characters long' } }}
                render={({ field, fieldState }) => (
                  <IconInput
                    error={fieldState.error}
                    icon={Password}
                    alt='User password'
                  >
                    <Input {...field} placeholder='Password' type='password' isInvalid={!!fieldState.error} />
                  </IconInput>
                )}
              />
              <Controller
                name='confirmPassword'
                control={control}
                rules={{ required: 'Please retype your password', validate: (value) => value === control._formValues.password || 'Passwords do not match' }}
                render={({ field, fieldState }) => (
                  <IconInput
                    error={fieldState.error}
                    icon={Password}
                    alt='Confirm Password'
                  >
                    <Input {...field} placeholder='Re-enter password' type='password' isInvalid={!!fieldState.error} />
                  </IconInput>
                )}
              />
            </Stack>
            <AsyncButton
              formState={formState}
              type='submit'
              mt={4}
              width='100%'
              height='54'
              onClick={handleSubmit(onSubmit)}
            >Register account
            </AsyncButton>
            <Link to='/booking'>
              <Button
                width='100%'
                mt={4}
                backgroundColor='transparent'
                fontSize='14'
                color='white'
                fontWeight='100'
                _hover={{ backgroundColor: 'transparent', color: 'gray.400' }}
              >Cancel
              </Button>
            </Link>
            <Flex alignItems='center' justifyContent='center' my={4} mt='45' mb='45'>
              <Box borderBottom='1px solid white' width='110px' />
              <Text mx={5}>Or</Text>
              <Box borderBottom='1px solid white' width='110px' />
            </Flex>
            <Link to='/login'>
              <Button
                type='submit'
                backgroundColor='gray.600'
                color='white'
                width='100%'
                height='54'
                _hover={{ backgroundColor: 'gray.700', color: 'white' }}
              >Sign in
              </Button>
            </Link>
          </form>
        </Box>
      </QuerySuspense>
    </Flex>
  );
}
