import {
  Button, ButtonGroup, Flex, Img, Tab, TabList, TabPanel, TabPanels, Tabs,
} from '@chakra-ui/react';
import {
  useContext, useMemo, useState,
} from 'react';
import { When } from 'react-if';
import { useLocation } from 'react-router';

import { FileTable } from './FileTable';
import { FileTreeView } from './FileTreeView';
import FileListContext from '../FileListContext';
import { Transfers } from 'api/transferServerTypes';
import DownloadIcon from 'assets/icons/download-files-icon.svg';
import ForwardIcon from 'assets/icons/forward-icon.svg';
import TableViewIcon from 'assets/icons/table-view.svg';
import TreeViewIcon from 'assets/icons/tree-view.svg';
import ChromeDownloadModal from 'components/ChromeDownloadModal';
import AsyncButton from 'components/form/AsyncButton';
import { ChromeSecurityNoticeModal } from 'components/google-context-modal/ChromeSecurityNoticeModal';
import SmsVerifyModal from 'components/SmsVerifyModal';
import { useAzureDownload } from 'hooks/useAzureDownload';
import useUserContext from 'hooks/useUserContext';
import { ForwardTransferModal } from 'pages/transfers/files-page/components/ForwardTransferModal';

export function FileViewTabs({ transfer }: { transfer?: Transfers }): React.ReactElement {
  const { fileList, isReadOnly } = useContext(FileListContext);
  const [activeTab, setActiveTab] = useState<string>('table');
  const [tableSelectedFiles, setTableSelectedFiles] = useState<{ FileId: number, FileName: string }[]>([]);
  const [treeSelectedFiles, setTreeSelectedFiles] = useState<number[]>([]);
  const {
    downloadBlobs, smsModalProps, chromeSecurityNotificationProps, chromeDownloadModalProps,
  } = useAzureDownload();
  const isArchive = useLocation().pathname.includes('archives');

  const [isForwardModalOpen, setIsForwardModalOpen] = useState(false);

  const { isStudioMember } = useUserContext();

  const filesToDownload = useMemo(() => {
    if (!fileList) return [];
    if (activeTab === 'table') {
      return fileList.filter((file, index) => tableSelectedFiles.find((f) => f.FileId === file.FileId));
    }
    if (activeTab === 'tree') {
      return fileList.filter((f) => treeSelectedFiles.includes(f.FileId));
    }
  }, [activeTab, treeSelectedFiles, tableSelectedFiles, fileList]);

  async function downloadSelectedBlobs(): Promise<void> {
    await downloadBlobs({
      blobs: filesToDownload?.length ? filesToDownload : fileList,
    });
  }

  return (
    <>
      <TabPanel>
        <SmsVerifyModal {...smsModalProps} />
        <ChromeSecurityNoticeModal {...chromeSecurityNotificationProps} />
        <ChromeDownloadModal {...chromeDownloadModalProps} />
        <Tabs variant='unstyled' isLazy lazyBehavior='unmount'>
          <Flex
            direction='row'
            justifyContent='flex-end'
            width='100%'
            gap='25px'
          >
            <TabList>
              <Tab
                background={activeTab === 'table' ? 'darkGold.1000' : 'inherit'}
                borderRadius='8px'
                px='4px'
                h='35px'
                w='35px'
                onClick={() => {
                  setActiveTab('table');
                  setTreeSelectedFiles([]);
                }}
              >
                <Img color='white' h='18px' w='18px' padding='2px' src={TableViewIcon} />
              </Tab>
              <Tab
                background={activeTab === 'tree' ? 'darkGold.1000' : 'inherit'}
                borderRadius='8px'
                px='4px'
                h='35px'
                w='35px'
                onClick={() => {
                  setActiveTab('tree');
                  setTableSelectedFiles([]);
                }}
              >
                <Img src={TreeViewIcon} h='20px' w='18px' />
              </Tab>
            </TabList>
            <ButtonGroup gap='15px'>
              <When condition={!isReadOnly && !transfer?.Canceled}>
                <AsyncButton
                  spinAfterPromiseResolvedSuccess={false}
                  variant='outline'
                  onClick={downloadSelectedBlobs}
                  leftIcon={<Img src={DownloadIcon} w='15px' h='11px' />}
                >
                  {filesToDownload && filesToDownload.length > 0
                    ? `Download ${filesToDownload.length} Files`
                    : 'Download All'}
                </AsyncButton>
                <When condition={!isArchive && isStudioMember}>
                  <Button
                    onClick={() => setIsForwardModalOpen(true)}
                    variant='outline'
                    leftIcon={<Img src={ForwardIcon} w='15px' h='11px' />}
                  >
                    Forward
                  </Button>
                </When>
              </When>
            </ButtonGroup>
          </Flex>
          <TabPanels>
            <TabPanel p='0px 0'>
              <FileTable
                createdByFilter={false}
                apiPath={`/file-api/transfers/${transfer?.TransferId}/file-list`}
                selectedFiles={tableSelectedFiles}
                setSelectedFiles={setTableSelectedFiles}
                projectNumber={transfer?.Project?.ProjectNumber!}
                invisibleColumns={{ CreatedBy: false }}
              />
            </TabPanel>
            <TabPanel p='25px 0'>
              <FileTreeView
                setTreeSelectedFiles={setTreeSelectedFiles}
                rootFolderName={isArchive ? `Archive - ${transfer?.TransferId}` : `Transfer - ${transfer?.TransferId}`}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </TabPanel>
      <When condition={isForwardModalOpen}>
        <ForwardTransferModal
          transfer={transfer!}
          files={activeTab === 'table' ? fileList.filter((file, index) => tableSelectedFiles[index]) : fileList.filter((f) => treeSelectedFiles.includes(f.FileId))}
          isOpen={isForwardModalOpen}
          onClose={() => setIsForwardModalOpen(false)}
        />
      </When>
    </>
  );
}
