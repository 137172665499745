import {
  Box, Button, Flex,
} from '@chakra-ui/react';
import { When } from 'react-if';
import { useWizard } from 'react-use-wizard';

import { WizardStepper } from './WizardStepper';

export type WizardStep = {
  component: React.ComponentType;
  stepTitle: string;
  key: string;
  hidden?: boolean
  allowBack: boolean;
  backToStart?: boolean;
};

export type WizardWrapperProps = {
  steps: WizardStep[];
  animationWrapper?: (props: React.PropsWithChildren) => React.ReactElement;
};

export function WizardWrapper(props: React.PropsWithChildren<WizardWrapperProps>): React.ReactElement {
  const {
    previousStep, isFirstStep, activeStep, goToStep,
  } = useWizard();
  const allowBack = props.steps[activeStep].allowBack && !isFirstStep;
  const backToStart = allowBack && props.steps[activeStep].backToStart;

  const handleBackClick = (): void => {
    // Skip register company hidden step
    if (activeStep === 4) {
      goToStep(2);
    } else if (backToStart) {
      goToStep(0);
    } else {
      previousStep();
    }
  };

  return (
    <Flex direction='column' gap='50px' paddingX='40px'>
      <WizardStepper steps={props.steps} />
      <Box>
        {props.children}
        <When condition={allowBack}>
          <Button onClick={handleBackClick} variant='back' width='100%'>Back</Button>
        </When>
      </Box>
    </Flex>
  );
}
