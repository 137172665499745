import {
  Button, Flex, Img, Spacer, Text,
} from '@chakra-ui/react';

import ChromeIcon from 'assets/icons/chromeblack.svg';

export function SwitchToChromeAlert(): React.ReactElement {
  return (
    <Flex px='54px' h='92px' w='100vw' background='red.50' alignItems='center' justifyContent='center' gap='15px'>
      <Text color='black' fontSize='15px' fontWeight='500'>
        You are currently using an unsupported browser. To get the best experience, we recommend using Google Chrome.
      </Text>
      <Button
        as='a'
        target='_blank'
        href='https://www.google.com/chrome/'
        size='lg'
        aria-label='Download Chrome'
      >
        <Img src={ChromeIcon} />
        <Text ml='10px' fontSize='15px' fontWeight='500'>Download Chrome</Text>
      </Button>
    </Flex>
  );
}
