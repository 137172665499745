import {
  Button, Flex, Image, Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import useTenantContext from 'hooks/useTenantContext';

export default function SuccessStep(): React.ReactElement {
  const { logoSrc } = useTenantContext();
  const navigate = useNavigate();
  return (
    <Flex alignItems='center' flexDirection='column'>
      <Image src={logoSrc} width='300px' />
      <Flex direction='column' maxWidth='372px' gap='25px'>
        <Text textAlign='center' fontSize='20px' mt='100px'>
          Your account has successfully been registered!
        </Text>
        <Button width='100%' onClick={() => void navigate('/booking/new')}>Next</Button>
      </Flex>
    </Flex>
  );
}
