import { components } from 'studio-api-clients/transfers-server';

export enum FileReviewStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum FileMixType {
  MAIN = 'MAIN',
  ALTERNATE = 'ALTERNATE',
  TV_TRACK = 'TV_TRACK',
}

export type Resources = components['schemas']['ResourcesDto'];
export type Artists = components['schemas']['ArtistsDto'];
export type Downloads = components['schemas']['DownloadDto'] & { Contact: Contacts };
export type FileDto = components['schemas']['FileDto'];
export type Files = Omit<FileDto, 'FileSize'> & { ReviewStatus: FileReviewStatus, MixType: FileMixType, FileSize: string };
export type Subscription = components['schemas']['SubscriptionWithResourceDto'];
export type ProjectContacts = components['schemas']['ProjectContactWithDetailsDto'];
export type Projects = components['schemas']['ProjectWithArtistDto'];
export type TransferRequests = components['schemas']['TransferRequestDto'];
export type TransferRestoreRequests = components['schemas']['TransferRequestCTRowDto'];
export type Transfers = components['schemas']['TransferDto'] & { Project: Partial<Projects> };
export type SmsEnrollment = components['schemas']['SmsEnrollmentsDto'];
export type Contacts = components['schemas']['ContactDto'];
export type ProjectContactWithDetailsDto = components['schemas']['ProjectContactWithDetailsDto'];

export type TransferCTRow = components['schemas']['TransferCTRowDto'];
export type HoldStatus = components['schemas']['HoldStatusDto'];

export type TransferRequestCTRow = components['schemas']['TransferRequestCTRowDto'];

export type ProjectCTRowDto = components['schemas']['ProjectCTRowDto'];

export type NameEmailPair = components['schemas']['NameEmailPairDto'];

export type ProjectOverviewResponse = components['schemas']['ProjectOverviewDto'];

export type TransferOverviewResponse = components['schemas']['TransferOverviewDto'];

export type ArchiveStatus = 'Restoring' | 'Restored' | 'Archived';

export type ArchiveOverviewResponse = {
  id: number,
  name: string,
  from: NameEmailPair
  date: Date,
  stats: {
    fileCount: number,
    fileSize: number,
  },
  status: ArchiveStatus,
};

export type ArchivedTransfersCTRow = {
  transferId: string,
  dateModified: Date,
  modifiedBy: string,
  restoring: boolean,
  restoredBy: string | null,
  dateRestored: Date | null,
  archived: boolean,
  filesCount: number | null,
  filesSize: bigint | null,
  artist: string | null,
  project: {
    id: number,
    externalSourceId: string | null,
    name: string,
  },
};

export type TransferWithMeta = components['schemas']['TransferWithMetaDto'];

export type OtpaInfoResponse = {
  needsOtpa: boolean; // true if user currently needs to enter OTPA to access the site
  otpaEnrolled: boolean;
  otpaEnrollmentDate: string | null;
};

export type TransferAuthentications = components['schemas']['TransferAuthenticationDto'];
