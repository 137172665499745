import {
  Button, ButtonGroup, Flex, FormLabel, Heading, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Select,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { studioServerApi } from 'api/studioServerApi';
import { transferServerApi } from 'api/transfersServerApi';
import { getContactDisplayName } from 'components/ContactDisplayName';
import AsyncButton from 'components/form/AsyncButton';
import LabelInput from 'components/form/LabelInput';
import { useApiQuery } from 'hooks/useApiQuery';
import { FileReviewStatus } from 'types/api';

interface IEditFileReviewModal {
  isOpen: boolean;
  onClose: () => void;
  files: { FileName: string, FileId: number }[];
  projectIdentifier: string;
}

interface IForm {
  ReviewStatus?: FileReviewStatus
  ReviewedByProjectContactIdentifier?: string
}

const selectProps = {
  size: 'lg',
  fontSize: '14px',
  _focusVisible: {
    borderColor: 'gray.400',
  },
};

const reviewStatus = [
  FileReviewStatus.APPROVED,
  FileReviewStatus.REJECTED,
];

export default function EditFileReviewModal({
  isOpen, onClose, files, projectIdentifier,
}: IEditFileReviewModal): React.ReactElement {
  const {
    register, handleSubmit, formState, watch, control,
  } = useForm<IForm>({});
  const queryClient = useQueryClient();

  const { errors } = formState;

  const projectContactQuery = useApiQuery({
    queryKey: [`/api/projects/${projectIdentifier}/project-contacts/display`],
    queryFn: () => studioServerApi.GET('/api/projects/{projectIdentifier}/project-contacts/display', {
      params: {
        path: {
          projectIdentifier,
        },
      },
    }),
  });

  const projectContactOptions = useMemo(() => {
    if (projectContactQuery.apiResult?.data) {
      return projectContactQuery.apiResult?.data.map((projectContact) => (
        <option key={projectContact.ProjectContactIdentifier} value={projectContact.ProjectContactIdentifier!}>{getContactDisplayName(projectContact, true)}</option>
      ));
    }

    return [];
  }, [projectContactQuery.apiResult?.data]);

  const onSubmit = handleSubmit(async (data) => {
    if (data.ReviewedByProjectContactIdentifier === '') {
      delete data.ReviewedByProjectContactIdentifier;
    }

    const dataToPost = {
      ReviewStatus: data.ReviewStatus!,
      ReviewedByProjectContactIdentifier: data.ReviewedByProjectContactIdentifier,
      FileIds: files.map((f) => f.FileId),
    };

    await transferServerApi.POST('/api-v2/files/review', { body: dataToPost });
    await queryClient.invalidateQueries({ queryKey: ['file-table'] });
    onClose();
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent py='35px' px='40px' borderRadius='15px' gap='25px'>
        <ModalHeader p='0'>
          <Heading fontSize='22px' fontWeight='700px'>{`Review ${files.length} ${pluralize('file', files.length)}`}</Heading>
        </ModalHeader>
        <ModalBody p='0'>
          <form>
            <Flex direction='column' gap='25px'>
              <Flex direction='column'>
                <LabelInput label='Status' error={formState.errors.ReviewStatus}>
                  <Controller
                    control={control}
                    name='ReviewStatus'
                    rules={{
                      required: { value: true, message: 'Required' },
                    }}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...register('ReviewStatus', {
                          validate: (value) => {
                            if (!value) {
                              return false;
                            }
                            return true;
                          },
                        })}
                        {...selectProps}
                        defaultValue=''
                      >
                        <option value='' disabled>Select status</option>
                        {reviewStatus.map((status) => (
                          <option key={status} value={status}>{status}</option>
                        ))}
                      </Select>
                    )}
                  />
                </LabelInput>
              </Flex>
              <Flex direction='column'>
                {/* // Use the New IconLabel/FormLabel components here */}
                <LabelInput label='Reviewer' error={formState.errors.ReviewedByProjectContactIdentifier}>
                  <Controller
                    control={control}
                    name='ReviewedByProjectContactIdentifier'
                    rules={{
                      required: { value: true, message: 'Required' },
                    }}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...register('ReviewedByProjectContactIdentifier', {
                          validate: (value) => {
                            if (watch('ReviewStatus') !== FileReviewStatus.PENDING && !value) {
                              return false;
                            }
                            return true;
                          },
                        })}
                        {...selectProps}
                        defaultValue=''
                      >
                        <option value='' disabled>Select Reviewer</option>
                        {projectContactOptions}
                      </Select>
                    )}
                  />
                </LabelInput>
              </Flex>
              <ButtonGroup size='100%' spacing='15px'>
                <AsyncButton
                  formState={formState}
                  type='submit'
                  onClick={onSubmit}
                  w='100%'
                  variant='solid'
                >
                  Update
                </AsyncButton>
                <Button
                  w='100%'
                  onClick={onClose}
                  variant='outline'
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
