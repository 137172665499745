import { ReactNode } from 'react';
import { When } from 'react-if';
import {
  Navigate,
} from 'react-router-dom';

import { Roles, hasRole } from 'contexts/user/claims';
import useIsSpecificRoute from 'hooks/useIsSpecificRoute';
import useUserContext from 'hooks/useUserContext';

export default function AuthorizeDisplay({ children, role }: { children: ReactNode, role: Roles }): React.ReactElement {
  const { user, isLoggedIn } = useUserContext();
  const { isBookingRoute } = useIsSpecificRoute();

  if (isBookingRoute && !isLoggedIn) return <Navigate to='/booking/sign-options' />;
  if (!isLoggedIn) return <Navigate to='/login' />;

  const inRole = hasRole(user?.Claims || [], role);
  return (
    <When condition={inRole}>
      {children}
    </When>
  );
}
