import {
  Flex, Tab, TabList, Tabs,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export type NavigationTabsProps = {
  tabName: MySterlingTabKeys,
  children?: React.ReactElement
};

export type MySterlingTabKeys = 'sessions' | 'invoices' | 'payment';

const tabMap: Record<MySterlingTabKeys, number> = {
  sessions: 0,
  invoices: 1,
  payment: 2,
};

export function MySterlingNavigationTabs({
  tabName, children,
}: NavigationTabsProps): React.ReactElement {
  return (
    <Tabs
      index={tabMap[tabName || 'sessions']}
      isLazy
      lazyBehavior='unmount'
      variant='outline'
    >
      <Flex
        flexDirection={['column', 'column', 'column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'flex-start', 'flex-start', 'center']}
        gap='4'
        justifyContent='space-between'
      >
        <TabList
          as={Flex}
          gap='2'
        >
          <RouterLink to='/booking/sessions'>
            <Tab>Sessions</Tab>
          </RouterLink>
          <RouterLink to='/booking/invoices'>
            <Tab>Invoices</Tab>
          </RouterLink>
        </TabList>
      </Flex>
      {children}
    </Tabs>
  );
}
