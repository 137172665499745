import {
  Flex, Img, Text,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import {
  BooleanLike, Else, If, Then,
} from 'react-if';

export function InfoSet({
  label,
  icon,
  condition = true,
  value,
}: {
  label: string,
  icon: string,
  condition?: BooleanLike | (() => BooleanLike),
  value: ReactNode | number,
}): React.ReactElement {
  return (
    <Flex
      flexDirection='column'
      gap='8px'
      fontWeight='400'
    >
      <Flex
        gap='5px'
        alignItems='center'
        opacity={0.5}
      >
        <Img height='20px' width='20px' src={icon} marginRight='7px' />
        <Text
          fontFamily='Poppins'
          fontSize='14px'
          fontWeight='700'
        >
          {label}
        </Text>
      </Flex>
      <If condition={condition && (value !== null && value !== undefined)}>
        <Then>
          {value === 0 ? '0' : value}
        </Then>
        <Else>
          -
        </Else>
      </If>
    </Flex>
  );
}
