import { Route, Routes } from 'react-router-dom';

import useAdminRoutes from './useAdminRoutes';
import useAuthRoutes from './useAuthRoutes';
import useBookingRoutes from './useBookingRoutes';
import usePortalRoutes from './usePortalRoutes';
import useReportRoutes from './useReportRoutes';
import useTransfersRoutes from './useTransfersRoutes';
import ErrorPage from '../components/error/ErrorPage';
import DevPage from '../pages/dev/DevPage';
import useTenantContext from 'hooks/useTenantContext';

export default function RouteIndex(): React.ReactElement {
  const authRoutes = useAuthRoutes();
  const portalRoutes = usePortalRoutes();
  const reportRoutes = useReportRoutes();
  const adminRoutes = useAdminRoutes();
  const bookingRoutes = useBookingRoutes();
  const transferRoutes = useTransfersRoutes();

  const { routes } = useTenantContext();

  return (
    <Routes>
      <Route path='*' element={<ErrorPage title='Page not found' />} />
      {authRoutes}
      {routes.transfers && transferRoutes}
      {routes.booking && bookingRoutes}
      {routes.admin && adminRoutes}
      {routes.reports && reportRoutes}
      {portalRoutes}
      <Route path='/dev' element={<DevPage />} />
    </Routes>
  );
}
