import { FormLabel, Stack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

import ErrorDisplay from 'components/error/ErrorDisplay';

export default function LabelInput({
  children, error, label,
}: { children: ReactNode, error?: FieldError, label: string }): React.ReactElement {
  return (
    <Stack spacing='0'>
      <FormLabel mb='0'>{label}</FormLabel>
      {children}
      <ErrorDisplay error={error} />
    </Stack>
  );
}
