import {
  Avatar,
  Box,
  Checkbox, Flex, Text,
} from '@chakra-ui/react';
import { useState } from 'react';

import { Subscription } from 'api/transferServerTypes';
import { transferServerApi } from 'api/transfersServerApi';
import useAlert from 'hooks/useAlert';

export default function ChannelCheckbox({ subscription }: { subscription: Subscription }): React.ReactElement {
  const [subscriptionState, setSubscriptionState] = useState<Subscription>(subscription);
  const [checkBoxesEnabled, setCheckBoxesEnabled] = useState(true);
  const { errorToast } = useAlert();

  const handleChange = async (webEnabled: boolean, emailEnabled: boolean): Promise<void> => {
    setSubscriptionState((prevState) => ({
      ...prevState,
      EmailEnabled: emailEnabled,
      WebEnabled: webEnabled,
    }));

    try {
      setCheckBoxesEnabled(false);
      const subscriptionUpdateResponse = await transferServerApi.POST('/api-v2/subscriptions/update', {
        body: {
          subscriptionId: subscriptionState.Id,
          emailEnabled,
          webEnabled,
          resourceIdentifier: subscription.ResourceIdentifier,
        },
      });
      setSubscriptionState(subscriptionUpdateResponse.data!);
    } catch (error) {
      errorToast(`Failed to update: ${subscriptionState.Resource.Name}`);
    } finally {
      setCheckBoxesEnabled(true);
    }
  };

  return (
    <Box border='solid 1px #4A5568' w='900px' borderRadius='15px' color='white' height='100px' display='flex' alignItems='center'>
      <Flex direction='row' align='center' w='818px' justifyContent='space-between'>
        <Flex align='center' gap='4' marginLeft='40px'>
          <Avatar name={subscriptionState.Resource.Name} borderColor='transparent' key={subscriptionState.Resource.Name} />
          <Text>{subscriptionState.Resource.Name}</Text>
        </Flex>
        <Box display='flex' flexDir='row' marginRight='40px' gap='170px'>
          <Flex dir='row'>
            <Checkbox
              disabled={!checkBoxesEnabled}
              isChecked={subscriptionState.WebEnabled}
              onChange={(e) => handleChange(e.target.checked, subscriptionState.EmailEnabled)}
            />
          </Flex>
          <Flex dir='row'>
            <Checkbox
              disabled={!checkBoxesEnabled}
              isChecked={subscriptionState.EmailEnabled}
              onChange={(e) => handleChange(subscriptionState.WebEnabled, e.target.checked)}
            />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
