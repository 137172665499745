import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Img,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import {
  Else, If, Then, When,
} from 'react-if';
import { useParams } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { useGate } from 'statsig-react';

import { ActivityIcon } from './components/ActivityIcon';
import { EmailIcon } from './components/EmailIcon';
import { FileIcon } from './components/FileIcon';
import { FileViewTabs } from './components/FileViewTabs';
import { HoldStatusBanner } from './components/HoldStatusBanner';
import TransferEmailTable from './components/TransferEmailTable';
import { TransferOverview } from './components/TransferOverview';
import { WorkOrderOverview } from './components/WorkOrderOverview';
import FileListContext from './FileListContext';
import { ProjectOverview } from '../project-overview-page/components/ProjectOverview';
import { ActivitiesTable } from '../projects-page/ActivitiesTable';
import { signOut } from 'api/auth';
import { transferServerApi } from 'api/transfersServerApi';
import TransferIcon from 'assets/icons/transfer.svg';
import TransferDeleteIcon from 'assets/icons/trash-icon-black.svg';
import AsyncButton from 'components/form/AsyncButton';
import { NavigationTabs } from 'components/NavigationTabs';
import QuerySuspense from 'components/QuerySuspense';
import { TransferDeleteModal } from 'components/table/TransferDeleteModal';
import { TabWithIcon } from 'components/TabWithIcon';
import { useApiQuery } from 'hooks/useApiQuery';
import useUserContext from 'hooks/useUserContext';
import { StatsigGates } from 'StatsigGates';

export function TransferPage(): React.ReactElement {
  const { transferId } = useParams();
  const transferQuery = useApiQuery({
    queryKey: ['transfer-query', transferId],
    queryFn: () => transferServerApi.GET('/api-v2/transfers/{transferId}', {
      params: {
        path: {
          transferId: Number(transferId!),
        },
      },
    }),
  });
  const transferFileListQuery = useApiQuery({
    queryKey: ['transfer-file-list', transferId],
    queryFn: () => transferServerApi.GET('/api-v2/transfers/{transferId}/file-list', {
      params: {
        path: {
          transferId: Number(transferId!),
        },
      },
    }),
  });
  const [tabIndex, setTabIndex] = useState(0);
  const transfer = useMemo(() => {
    if (!transferQuery.apiResult?.data) return undefined;
    return transferQuery.apiResult.data;
  }, [transferQuery.apiResult?.data]);

  const { user, isStudioMember } = useUserContext();
  const requestedEmailAddress = useSearchParam('email');

  const handleReauthenticate = async (): Promise<void> => {
    const paramProps: Record<string, string> = {
      redirectTo: `/transfers/transfers/${transferId}`,
    };
    if (requestedEmailAddress) {
      paramProps.email = requestedEmailAddress;
    }

    const searchParams = new URLSearchParams(paramProps);
    await signOut(searchParams);
  };

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { value: releaseAllFilesGate } = useGate(StatsigGates.RELEASE_ALL_FILES_ON_HOLD);

  const emailText = (email: string): React.ReactElement => (
    <Text
      as='span'
      fontWeight='700'
      textDecoration='underline'
    >
      {email}
    </Text>
  );

  const filePageContext = useMemo(() => ({
    // If the transfer is expired, it stays read-only no matter what.
    // If it's on hold and the gate is not enabled, it's read-only.
    // If it's on hold but the gate is enabled, it's not read-only.
    isReadOnly: (transfer?.isExpired || (transfer?.holdStatus?.isOnHold && !releaseAllFilesGate && !isStudioMember)) ?? true,
    fileList: transferFileListQuery.apiResult?.data || [],
    isArchive: false,
  }), [transfer?.isExpired, transfer?.holdStatus, transferFileListQuery.apiResult?.data, releaseAllFilesGate, isStudioMember]);

  return (
    <If condition={requestedEmailAddress && user?.EmailAddress !== requestedEmailAddress}>
      <Then>
        <Flex
          justifyContent='center'
        >
          <Box
            maxWidth='800px'
            minWidth='600px'
            bgColor='gray.800'
            padding='40px 60px'
            borderRadius={15}
          >
            <Flex direction='column' alignItems='left' gap='20px'>
              <Heading fontSize='23px'>Transfer Access Notice</Heading>
              <Text>
                You are trying to access a transfer from an email that was sent to {emailText(requestedEmailAddress!)},
                but you are currently logged in as {emailText(user?.EmailAddress!)}.
              </Text>
              <Text>To access this transfer, please log out and log back in using {emailText(requestedEmailAddress!)}.</Text>
              <Box
                mt='25px'
                display='inline-block'
              >
                <AsyncButton onClick={handleReauthenticate} padding='15px 25px'>
                  <Text>
                    Sign in as {emailText(requestedEmailAddress!)}
                  </Text>
                </AsyncButton>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Then>
      <Else>
        <QuerySuspense queries={[transferQuery.queryResult, transferFileListQuery.queryResult]}>
          <FileListContext.Provider value={filePageContext}>
            <Flex direction='column' gap='25px' width='100%' justifyContent='center'>
              <NavigationTabs tabName='transfers' hideSearchBar disableSelectedStyling />
              <Flex justifyContent='space-between'>
                <Flex height='30px' align='center' gap='10px'>
                  <Img src={TransferIcon} w='20px' h='15px' />
                  <Heading fontWeight='700' fontSize='20px'>Transfer #{transferId}: Sources</Heading>
                </Flex>
                <When condition={!transfer?.Canceled && isStudioMember}>
                  <Button
                    onClick={() => setIsDeleteDialogOpen(true && !transfer?.Canceled)}
                    leftIcon={<Img src={TransferDeleteIcon} h='15px' />}
                    height='54px'
                  >
                    Delete Transfer
                  </Button>
                </When>
              </Flex>
              <When condition={transfer?.Canceled}>
                <Alert status='error' height='80px'>
                  <AlertIcon />
                  <AlertTitle>This transfer has been deleted</AlertTitle>
                </Alert>
              </When>
              <When condition={transfer?.isExpired}>
                <Alert status='warning' height='80px'>
                  <AlertIcon />
                  <AlertTitle>This transfer has expired</AlertTitle>
                </Alert>
              </When>
              <When condition={transfer?.holdStatus?.isOnHold && !releaseAllFilesGate}>
                <HoldStatusBanner isUpload={false} holdStatus={transfer?.holdStatus!} transfer={transfer} />
              </When>
              <Flex direction='column' gap='25px'>
                <When condition={!!transfer}>
                  <ProjectOverview projectId={transfer?.Project?.ProjectNumber!} />
                  <TransferOverview transferId={transferId} />
                  <When condition={!!transfer?.WorkOrderIdentifier && !!isStudioMember}>
                    <WorkOrderOverview
                      projectIdentifier={transfer?.Project?.ProjectIdentifier!}
                      workOrderIdentifier={transfer?.WorkOrderIdentifier!}
                    />
                  </When>
                </When>
              </Flex>
              <Tabs isLazy lazyBehavior='unmount' index={tabIndex} onChange={setTabIndex}>
                <TabList>
                  <TabWithIcon
                    icon={FileIcon()}
                    label='Files'
                  />
                  <TabWithIcon
                    icon={ActivityIcon()}
                    label='Activities'
                  />
                  <When condition={isStudioMember}>
                    <TabWithIcon
                      icon={EmailIcon()}
                      label='Emails'
                    />
                  </When>
                </TabList>
                <TabPanels>
                  <FileViewTabs transfer={transfer} />
                  <TabPanel>
                    <ActivitiesTable transferId={transferId} />
                  </TabPanel>
                  <When condition={isStudioMember}>
                    <TabPanel>
                      <TransferEmailTable transferId={transferId} />
                    </TabPanel>
                  </When>
                </TabPanels>
              </Tabs>
            </Flex>
          </FileListContext.Provider>
          <When condition={isDeleteDialogOpen}>
            <TransferDeleteModal
              transferId={transferId!}
              transferName={transfer?.TransferName}
              isOpen={isDeleteDialogOpen}
              onClose={() => setIsDeleteDialogOpen(false)}
            />
          </When>
        </QuerySuspense>
      </Else>
    </If>
  );
}
