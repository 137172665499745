import { useToast } from '@chakra-ui/react';
import {
  ReactNode, createContext, useCallback, useEffect, useMemo, useState,
} from 'react';

// eslint-disable-next-line no-restricted-imports
import SterlingSoundLogo from 'assets/sterling-sound-light-logo.svg';
// eslint-disable-next-line no-restricted-imports
import VillageLogo from 'assets/village-logo-white.svg';

// eslint-disable-next-line import/no-mutable-exports
let tenantHeaders: Record<string, string> = {};
function setTenantHeaders(tenant: Tenants): void {
  tenantHeaders = tenantConfigs[tenant].headers();
}
export function getTenantHeaders(): Record<string, string> {
  return tenantHeaders;
}

export enum Tenants {
  VILLAGE = 'village',
  STERLING_SOUND = 'sterling-sound',
}

export interface IFeatureFlags {
  subscriptions: boolean;
  integrations: boolean;
  receivableReports: boolean;
  securitySettings: boolean;
  bookingSignUp: boolean;
  login: boolean;
  paymentSettings: boolean;
  logout: boolean;
  tenantHasOAuth?: boolean;
  tenantHasTransfers?: boolean;
}

export interface TenantConfig {
  routes: {
    admin: boolean;
    booking: boolean;
    transfers: boolean;
    reports: boolean;
  };
  features: IFeatureFlags
  tenant: string;
  headers: () => Record<string, string>;
  logoSrc: string;
  validateLogin: (email: string) => void;
}

const isValidTenant = (value: any): value is Tenants => Object.values(Tenants).includes(value as Tenants);

export const tenantConfigs: Record<Tenants, TenantConfig> = {
  [Tenants.STERLING_SOUND]: {
    routes: {
      admin: true,
      booking: true,
      transfers: true,
      reports: true,
    },
    features: {
      subscriptions: true,
      integrations: true,
      receivableReports: true,
      securitySettings: true,
      bookingSignUp: true,
      login: true,
      paymentSettings: true,
      logout: true,
      tenantHasOAuth: true,
      tenantHasTransfers: true,
    },
    tenant: Tenants.STERLING_SOUND,
    headers: () => ({
      'X-AccountNumber': '1',
    }),
    logoSrc: SterlingSoundLogo,
    validateLogin: (email: string) => {
      if (!email.endsWith('@sterling-sound.com')) {
        throw new Error('Not implemented yet. Errors are reset in Tenant Context.');
      }
    },
  },
  [Tenants.VILLAGE]: {
    routes: {
      admin: false,
      booking: false,
      transfers: false,
      reports: true,
    },
    features: {
      subscriptions: false,
      integrations: false,
      receivableReports: true,
      securitySettings: true,
      bookingSignUp: false,
      login: false,
      paymentSettings: false,
      logout: true,
      tenantHasOAuth: false,
      tenantHasTransfers: false,
    },
    tenant: Tenants.VILLAGE,
    headers: () => ({
      'X-AccountNumber': '41',
      'X-UserClaim': 'StudioMember',
    }),
    logoSrc: VillageLogo,
    validateLogin: (email: string) => {
      if (!email.endsWith('@villagestudios.com')) {
        throw new Error('Not implemented yet. Errors are reset in Tenant Context.');
      }
    },
  },
};

export const TenantContext = createContext<TenantConfig>(tenantConfigs[Tenants.STERLING_SOUND]);

function determineTenant(): Tenants {
  const localStorageTenant = localStorage.getItem('tenant');

  if (isValidTenant(localStorageTenant)) {
    return localStorageTenant;
  }

  if (window.location.hostname.includes('village')) {
    return Tenants.VILLAGE;
  }

  return Tenants.STERLING_SOUND;
}

export function TenantContextProvider({ children }: { children: ReactNode }): React.ReactElement {
  const [tenant, setTenant] = useState<Tenants>(determineTenant());
  const toast = useToast();

  useEffect(() => {
    setTenantHeaders(tenant);
    localStorage.setItem('tenant', tenant);
  }, [tenant]);

  const tenantConfig = tenantConfigs[tenant];

  const validateLogin = useCallback((email: string) => {
    const lowerEmail = email.toLowerCase();

    if (tenant === Tenants.STERLING_SOUND && lowerEmail.endsWith('@sterling-sound.com') && lowerEmail !== 'polyfill@sterling-sound.com') {
      toast({
        title: 'Error',
        description: 'Sterling users must login with Microsoft.',
        status: 'error',
        duration: 5000,
      });
      throw new Error('Sterling users must login with Microsoft.');
    }
    if (tenant === Tenants.VILLAGE && !(lowerEmail.endsWith('@villagestudios.com') || lowerEmail === 'polyfill@sterling-sound.com')) {
      toast({
        title: 'Error',
        description: 'Must be a member of Village with an -@villagestudio.com email address to Login.',
        status: 'error',
        duration: 5000,
      });
      throw new Error('Village users must login with Village.');
    }
  }, [tenant, toast]);

  const state = useMemo(() => ({
    ...tenantConfig,
    validateLogin,
  }), [tenantConfig, validateLogin]);

  return (
    <TenantContext.Provider value={state}>
      {children}
    </TenantContext.Provider>
  );
}
