import { Row, createColumnHelper } from '@tanstack/react-table';
import { startCase } from 'lodash';

import { TransferEmails } from 'api/transferServerTypes';
import TransferEmailIcon from 'assets/icons/transfer-email-icon.svg';
import DateComponent from 'components/DateComponent';
import {
  DateHeader,
  EmailStatusHeader,
  EmailTemplateHeader,
  RecipientEmail,
} from 'components/table/headers';
import StudiumTable from 'components/table/StudiumTable';
import { getDate } from 'utils/formatter';

export type TransfersTableColumns = Pick<TransferEmails, 'RecipientEmail' | 'DateCreated' | 'DateSent' | 'EmailTemplate' | 'Status'>;

export default function TransferEmailTable({
  transferId,
}: { transferId: string | undefined }): React.ReactElement {
  const columnHelper = createColumnHelper<TransfersTableColumns>();

  function getStatusText(row: TransfersTableColumns): string {
    if (row.DateSent) {
      return `${startCase(row.Status)} ${getDate(row.DateSent)}`;
    }

    return startCase(row.Status);
  }

  const transferColumns = [
    columnHelper.accessor('DateCreated', {
      cell: (info) => (() => (
        <DateComponent date={info.getValue()} />
      ))(),
      header: DateHeader,
    }),
    columnHelper.accessor('RecipientEmail', {
      cell: (info) => info.getValue(),
      header: RecipientEmail,
    }),
    columnHelper.accessor('EmailTemplate', {
      cell: (info) => startCase(info.getValue()),
      header: EmailTemplateHeader,
    }),

    columnHelper.accessor('Status', {
      cell: (info) => getStatusText(info.row.original),
      header: EmailStatusHeader,
    }),
  ];

  return (
    <StudiumTable
      apiPath={`/api-v2/transfers/${transferId}/emails`}
      columns={transferColumns}
      stateKey='state'
      emptyTableState={{
        message: 'Transfer has no emails yet',
        icon: TransferEmailIcon,
      }}
      queryKeys={['transfer-email-table']}
    />
  );
}
