import {
  Button, Flex, TabPanel, TabPanels, Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MySterlingNavigationTabs, MySterlingTabKeys } from './components/MySterlingNavigationTabs';
import { InvoiceList } from './invoices/InvoiceList';
import ProjectList from './sessions/components/ProjectList';

function NewSessionButton() {
  const navigate = useNavigate();
  return (
    <Button onClick={() => void navigate('/booking/new')} width='200px'>
      <Text>Book a new session</Text>
    </Button>
  );
}

export function NavigationPanel({ tabName }: { tabName: MySterlingTabKeys }): React.ReactElement {
  return (
    <>
      <NewSessionButton />
      <MySterlingNavigationTabs tabName={tabName}>
        <TabPanels>
          <TabPanel mt='50' padding={0}>
            <ProjectList />
          </TabPanel>
          <TabPanel mt='50' padding={0}>
            <InvoiceList />
          </TabPanel>
        </TabPanels>
      </MySterlingNavigationTabs>
    </>
  );
}

function isMySterlingTabKeys(tabName: string): tabName is MySterlingTabKeys {
  return tabName === 'sessions' || tabName === 'invoices' || tabName === 'payment';
}

export default function MySterlingPage({ children }: { children?: React.ReactElement }): React.ReactElement {
  const { pathname } = useLocation();
  const tabName = pathname.split('/')[2];

  if (!isMySterlingTabKeys(tabName)) {
    throw new Error('Page not found');
  }

  return (
    <Flex justifyContent='center' alignItems='flex-start'>
      <Flex direction='column' gap='40px'>
        <NavigationPanel tabName={tabName} />
      </Flex>
    </Flex>
  );
}
