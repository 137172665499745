import { Flex } from '@chakra-ui/react';

import { TransferTask } from './TransferTask';
import { useTransfers } from 'contexts/transfer/TransferProvider';

export function TransferTaskList(): React.ReactElement {
  const {
    transferTasks,
    onUploadComplete,
    getActiveId,
  } = useTransfers();

  return (
    <Flex direction='column' gap='25px' mt='50px'>
      {transferTasks?.map((task) => (
        <TransferTask
          key={task.id}
          {...task}
          onUploadComplete={onUploadComplete}
          isMyTurn={getActiveId() === task.id}
        />
      ))}
    </Flex>
  );
}
