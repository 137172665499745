import { User } from 'firebase/auth';
import {
  ReactNode,
  createContext, useEffect, useMemo, useState,
} from 'react';

import { Roles, hasRole } from './claims';
import {
  fetchUserInfo,
  firebaseAuth,
} from 'api/auth';
import { transferServerApi } from 'api/transfersServerApi';
import { AsyncWrapper } from 'components/AsyncWrapper';
import { Sentry } from 'configureSentry';
import { IFeatureFlags } from 'contexts/tenant/TenantContextProvider';
import useIsSpecificRoute from 'hooks/useIsSpecificRoute';
import { OtpaInfoResponse } from 'types/api';
import { IUserProfileInfo } from 'types/studio-server';

export interface IUserState {
  user: IUserProfileInfo | undefined,
  isLoggedIn: boolean | undefined
  isStudioMember: boolean | undefined
  isStudioClient: boolean | undefined
  otpaRequired: boolean | undefined
  otpaEnrollmentDate: Date | undefined
  signInProvider: string | undefined
  menuLinks: IFeatureFlags
  refreshFirebase: () => Promise<User | null>
}

export const UserContext = createContext<IUserState>({
  user: undefined,
  isLoggedIn: undefined,
  otpaRequired: undefined,
  isStudioMember: undefined,
  isStudioClient: undefined,
  otpaEnrollmentDate: undefined,
  signInProvider: undefined,
  menuLinks: {
    subscriptions: false,
    integrations: false,
    receivableReports: false,
    securitySettings: false,
    bookingSignUp: false,
    login: false,
    paymentSettings: false,
    logout: false,
  },
  refreshFirebase: async () => null,
});

export default function UserContextProvider({ children }: { children: ReactNode }): React.ReactElement {
  const [userInfo, setUserInfo] = useState<IUserProfileInfo>();
  const [otpaInfo, setOtpaInfo] = useState<OtpaInfoResponse>();
  const [signInProvider, setSignInProvider] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  const {
    isBookingRoute,
  } = useIsSpecificRoute();

  const refreshFirebase = async (): Promise<User | null> => {
    await firebaseAuth.currentUser?.reload();
    return firebaseAuth.currentUser;
  };

  const state: IUserState = useMemo(() => ({
    user: userInfo,
    isLoggedIn: !!userInfo && otpaInfo?.needsOtpa === false,
    otpaRequired: otpaInfo?.needsOtpa,
    isStudioMember: hasRole(userInfo?.Claims || [], Roles.StudioMember),
    isStudioClient: hasRole(userInfo?.Claims || [], Roles.StudioClient),
    otpaEnrollmentDate: otpaInfo?.otpaEnrollmentDate ? new Date(otpaInfo.otpaEnrollmentDate) : undefined,
    signInProvider,
    menuLinks: {
      integrations: hasRole(userInfo?.Claims || [], Roles.StudioMember),
      receivableReports: hasRole(userInfo?.Claims || [], Roles.StudioMember),
      bookingSignUp: isBookingRoute && userInfo === undefined,
      login: isBookingRoute && userInfo === undefined,
      paymentSettings: isBookingRoute && userInfo !== undefined,
      subscriptions: true,
      securitySettings: !!userInfo,
      logout: !!userInfo,
    },
    refreshFirebase,
  }), [
    otpaInfo?.needsOtpa,
    otpaInfo?.otpaEnrollmentDate,
    userInfo,
    signInProvider,
    isBookingRoute,
  ]);

  // useLogger('userContext');

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((user) => {
      if (!user) {
        setIsLoading(false);
        setOtpaInfo(undefined);
        setUserInfo(undefined);
        setSignInProvider(undefined);
      } else {
        setIsLoading(true);
        Promise.all([fetchUserInfo(), transferServerApi.GET('/api-v2/otpa'), user.getIdTokenResult()])
          .then(([studioUser, otpa, idTokenResult]) => {
            setUserInfo(studioUser);
            setOtpaInfo(otpa.data);
            setSignInProvider(idTokenResult.signInProvider!);
            Sentry.getCurrentScope().setExtra('otpa', otpa.data);
            Sentry.getCurrentScope().setTag('signInProvider', idTokenResult.signInProvider);
          })
          .catch(console.error)
          .finally(() => setIsLoading(false));
      }
    });
  }, []);

  return (
    <AsyncWrapper requests={[{ loading: isLoading }]}>
      <UserContext.Provider value={state}>
        {children}
      </UserContext.Provider>
    </AsyncWrapper>

  );
}
