import {
  Box, Flex, Image, Text, Tooltip,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useInterval } from 'react-use';
import { useWizard } from 'react-use-wizard';

import BookingExpiredModal from './BookingExpiredModal';
import { steps } from '../BookingWizard';
import Clock from 'assets/icons/clock.svg';
import { useBookingContext } from 'contexts/booking/BookingContext';

const TIMER_LIMIT = 300000; // 5 minutes

const formatRemainingTime = (remainingTime: number) => {
  const minutes = Math.floor(remainingTime / 60000);
  const seconds = Math.floor((remainingTime % 60000) / 1000);

  const paddedMinutes = String(minutes).padStart(2, '0');
  const paddedSeconds = String(seconds).padStart(2, '0');

  return `${paddedMinutes}:${paddedSeconds}`;
};

export function BookingWizardTimer(): React.ReactElement {
  const { timerStartTime, mastersByDate } = useBookingContext();
  const { activeStep } = useWizard();
  const [remainingTime, setRemainingTime] = useState<number>(0);

  useInterval(() => {
    const elapsedTime = Date.now() - timerStartTime;
    setRemainingTime(TIMER_LIMIT - elapsedTime);
  }, 1000);

  const scheduleSessionStepKey = 'schedule-session-step';
  const scheduleSessionStepIndex = steps.findIndex((step) => step.key === scheduleSessionStepKey);
  if (scheduleSessionStepIndex === -1) {
    throw new Error(`Step with key ${scheduleSessionStepKey} not found`);
  }

  if (activeStep < scheduleSessionStepIndex) {
    return <></>;
  }

  if (!mastersByDate) {
    return <></>;
  }

  return (
    <>
      <BookingExpiredModal
        isOpen={remainingTime <= 0}
      />
      {remainingTime > 0 && (
        <Box border='2px' borderColor='gray.700' p='4' borderRadius='12px' mb='35px'>
          <Tooltip
            hasArrow
            label='This timer shows how long we&#39;ll hold this booking during checkout.
            If time runs out, other users can book the date, but you can still book it later if available.'
          >
            <Flex align='center'>
              <Image src={Clock} alt='Clock' width='20px' mr='7px' />
              <Text fontSize='18px' color='white'>Time Remaining: {formatRemainingTime(remainingTime)}</Text>
            </Flex>
          </Tooltip>
        </Box>
      )}
    </>
  );
}
