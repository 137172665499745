import {
  Box, SpinnerProps,
} from '@chakra-ui/react';
import { QueryErrorResetBoundary, UseQueryResult } from '@tanstack/react-query';
import { HTMLAttributes } from 'react';
import { Else, If, Then } from 'react-if';

import CustomErrorBoundary from './error/CustomErrorBoundary';
import ErrorPage from './error/ErrorPage';
import StudiumSpinner from './StudiumSpinner';
import { logError } from 'utils/error';

interface QuerySuspenseProps extends HTMLAttributes<HTMLDivElement> {
  queries: UseQueryResult<unknown>[];
  mountChildrenDiscrete?: boolean;
  spinnerProps?: SpinnerProps;
}

export default function QuerySuspense({
  queries,
  children,
  mountChildrenDiscrete = false,
  spinnerProps,
}: QuerySuspenseProps): React.ReactElement {
  const erroredQueryResult = queries.find((queryResult) => queryResult.isError);

  if (erroredQueryResult) {
    logError(erroredQueryResult.error);
    return (
      <ErrorPage error={erroredQueryResult.error!} />
    );
  }

  const isPending = queries.some((queryResult) => queryResult.isPending);

  if (isPending) {
    return (
      <StudiumSpinner optionalSpinnerProps={spinnerProps} />
    );
  }

  return (
    <QueryErrorResetBoundary>
      {() => (
        <CustomErrorBoundary>
          <If condition={mountChildrenDiscrete}>
            <Then>
              {children}
            </Then>
            <Else>
              <Box display='block'>
                {children}
              </Box>
            </Else>
          </If>
        </CustomErrorBoundary>
      )}
    </QueryErrorResetBoundary>

  );
}
