import { Heading, Stack } from '@chakra-ui/react';
import {
  Navigate, Outlet, useLocation,
} from 'react-router-dom';

import useIsSpecificRoute from 'hooks/useIsSpecificRoute';
import useUserContext from 'hooks/useUserContext';

export default function PrivateRoute({ allowContact = false }: { allowContact?: boolean }): React.ReactElement {
  const { isStudioMember, isLoggedIn } = useUserContext();
  const { pathname } = useLocation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const { isBookingRoute } = useIsSpecificRoute();

  if (isBookingRoute && !isLoggedIn) {
    return (
      <Navigate to={searchParams.toString() !== ''
        ? `/booking/sign-options?${searchParams.toString()}` : '/booking/sign-options'}
      />
    );
  }
  if (!isLoggedIn) return <Navigate to={`/login?redirectTo=${pathname}&${searchParams.toString()}`} />;

  if (isStudioMember) return <Outlet />;
  if (!isStudioMember && allowContact) return <Outlet />;

  console.log(`PrivateRoute: [${pathname}] user is not a user or contact, redirecting to login`);

  return (
    <Stack marginBottom='50px'>
      <Heading fontSize='4xl' as='b'>You don&apos;t have access to this page</Heading>
    </Stack>
  );
}
