import {
  Box, Flex, Grid, GridItem, Table, TableContainer, Tbody, Td, Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import { When } from 'react-if';

import { studioServerApi } from 'api/studioServerApi';
import AsyncButton from 'components/form/AsyncButton';
import { BookingState, useBookingContext } from 'contexts/booking/BookingContext';
import { formatCurrency } from 'utils/formatter';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const redirectToCheckout = async (publishableKey: string, sessionId: string) => {
  const stripe = await loadStripe(publishableKey);
  if (!stripe) throw new Error('Could not connect to payment provider');
  await stripe.redirectToCheckout({
    sessionId,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const postSessionRequest = async (state: BookingState) => {
  const AccountNumber = state.client?.AccountNumber;
  const ArtistName = state.sessionDetails?.artistName;
  const ClientIdentifier = state.client?.ClientIdentifier;
  const ContactIdentifier = state.contact?.ContactIdentifier;
  const LineItems = state.lineItems;
  const MastersByDate = state.mastersByDate;
  const ProjectName = state.sessionDetails?.sessionName;
  const ResourceIdentifier = state.resource?.ResourceIdentifier;
  const BaseUrl = window.location.origin;
  const Notes = state.sessionDetails?.notes;

  const response = studioServerApi.POST('/api/sessions', {
    body: {
      AccountNumber,
      ArtistName,
      ClientIdentifier,
      ContactIdentifier,
      LineItems,
      MastersByDate,
      ProjectName,
      ResourceIdentifier,
      BaseUrl,
      Notes,
    },
  });

  return (await response).data!;
};

export default function ReviewConfirm(): React.ReactElement {
  const state = useBookingContext();

  const { mutateAsync } = useMutation({
    mutationKey: ['/api/sessions', state.mastersByDate],
    mutationFn: postSessionRequest,
    onSuccess: async (data) => {
      await redirectToCheckout(data.StripePublishableKey!, data.StripeSessionId!);
    },
  });

  // eslint-disable-next-line eslint-comments/no-duplicate-disable
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onClick = async () => {
    await mutateAsync(state);
  };

  return (
    <Box>
      <Box>
        <Box backgroundColor='#0F1118' p='10px 14px' borderRadius='8' mb='15px' mt='25px'>
          <Text>Session Details</Text>
        </Box>
        <Grid templateColumns='max-content auto' gap={3} mb='25px'>
          <GridItem>
            <Text color='gray.400'>Date:</Text>
          </GridItem>
          <GridItem>
            <Text>{format(state.mastersByDate!, 'MMM dd, yyyy')}</Text>
          </GridItem>
          <GridItem>
            <Text color='gray.400'>Artist Name:</Text>
          </GridItem>
          <GridItem>
            <Text>{state.sessionDetails?.artistName}</Text>
          </GridItem>
          <GridItem>
            <Text color='gray.400'>Session Name:</Text>
          </GridItem>
          <GridItem>
            <Text>{state.sessionDetails?.sessionName}</Text>
          </GridItem>
          <GridItem>
            <Text color='gray.400'>Notes:</Text>
          </GridItem>
          <GridItem>
            <Text>{state.sessionDetails?.notes || 'There are no session notes'}</Text>
          </GridItem>
        </Grid>
        <Box backgroundColor='#0F1118' p='10px 14px' borderRadius='8' mb='0px'>
          <Text>Order Details</Text>
        </Box>
        <TableContainer>
          <Table variant='booking' mt='15px'>
            <Thead
              backgroundColor='transparent'
            >
              <Tr>
                <Th>
                  Qt
                </Th>
                <Th>Description</Th>
                <Th>Rate</Th>
              </Tr>
            </Thead>
            <Tbody>
              {state.lineItems!.map((lineItem, index) => (
                <When condition={lineItem.Quantity}>
                  <Tr key={lineItem.RateGroupItemIdentifier}>
                    <Td>{lineItem.Quantity}</Td>
                    <Td>{lineItem.Description}</Td>
                    <Td>{formatCurrency(lineItem.Rate)}</Td>
                  </Tr>
                </When>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Box borderBottom='1px solid #2D3748' width='100%' mb='25px' mt='10px' />
        <Box mt='15px'>
          <Flex justifyContent='space-between'>
            <Text fontSize='20px'>Subtotal:</Text>
            <Text fontSize='20px'>{formatCurrency(state.lineItems!.reduce((acc, row) => acc + row.Quantity! * row.Rate, 0))}</Text>
          </Flex>
        </Box>
      </Box>
      <AsyncButton onClick={onClick} variant='solid' width='100%' mt='25px' mb='15px'>Pay now</AsyncButton>
    </Box>
  );
}
