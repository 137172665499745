import { Box, Stack } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';

export function ControlledSelect({
  options,
  value,
  onChange,
  isSearchable = true,
  placeHolder,
}: {
  options: { label: string; value: string | null }[];
  value: string | null;
  onChange: (value: string | null) => void;
  isSearchable?: boolean;
  placeHolder: string
}): React.ReactElement {
  return (
    <Stack>
      <Select
        options={options}
        value={options.find((opt) => opt.value === value) || null}
        onChange={(option) => onChange(option?.value ?? null)}
        placeholder={(
          <Box display='flex' gap='5' pl={1} color='gray.400'>
            {placeHolder}
          </Box>
        )}
        isSearchable={isSearchable}
      />
    </Stack>
  );
}
