import {
  Tooltip,
} from '@chakra-ui/react';

import { getDate } from '../utils/formatter';

export default function DateComponent({ date, showPlaceholder = true }:{ date?: string | Date | null, showPlaceholder?: boolean }): React.ReactElement {
  if (!date) return showPlaceholder ? <>-</> : <></>;

  return (
    <Tooltip label={getDate(date, 'Pp')} placement='top'>
      {getDate(date)}
    </Tooltip>
  );
}
