import { Link, Tooltip } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Else, If, Then } from 'react-if';

interface LinkWithButtonStyleProps {
  href: string;
  children: ReactNode;
  tooltip?: string;
  style?: React.CSSProperties;
}

export function LinkWithButtonStyle({
  href,
  children,
  tooltip,
  style,
}: LinkWithButtonStyleProps): React.ReactElement {
  const link = (
    <Link
      href={href}
      sx={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        backgroundColor: 'gray.600',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '14px',
        borderRadius: '10px',
        height: '32px',
        width: '128px',
        textAlign: 'center',
        padding: '0 12px',
        transition: 'background-color 0.2s',
        ...style,
      }}
    >
      {children}
    </Link>
  );

  return (
    <If condition={!!tooltip}>
      <Then>
        <Tooltip label={tooltip} placement='top'>
          {link}
        </Tooltip>
      </Then>
      <Else>
        {link}
      </Else>
    </If>

  );
}
