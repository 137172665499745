import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Icon,
  Img, Table, TableContainer, Tbody, Td, Th, Thead, Tr,
} from '@chakra-ui/react';
import {
  ColumnDef,
  Row,
  Table as TanstackTable,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { addDays, differenceInCalendarDays } from 'date-fns';
import React, { useMemo, useState } from 'react';

import { InvoiceFilters } from './InvoiceFilters';
import PDFIcon from 'assets/icons/view-po.svg';
import DateComponent from 'components/DateComponent';
import LinkWithTooltip from 'components/LinkWithTooltip';
import { BalanceCard } from 'pages/portal/ContactStatementPage/components/BalanceCard';
import { InvoiceStatus, InvoiceStatusDisplay, getStatusMessage } from 'pages/portal/ContactStatementPage/components/InvoiceStatusDisplay';
import { ClientInvoicesDto, ReportAccountsReceivableInvoice } from 'types/studio-server';
import { formatCurrency } from 'utils/formatter';

type RowData = ReportAccountsReceivableInvoice & {
  Total?: number;
  SubRows?: RowData[];
};

function TotalAmount({ table }: { table: TanstackTable<RowData> }): React.ReactElement {
  const today = new Date();

  const { totalDue, totalOverdue } = table.getFilteredRowModel().flatRows.reduce(
    (acc: {
      totalDue: number;
      totalOverdue: number;
    }, row: Row<RowData>) => {
      const invoices = row.subRows?.length ? row.subRows : [row.original];

      invoices.forEach((invoice: RowData) => {
        if (!invoice.InvoiceDate || invoice.AmountOwed === undefined) {
          return;
        }

        const invoiceDate = new Date(invoice.InvoiceDate);
        const netDays = invoice.IsMajorLabel ? 60 : 30;
        const dueDate = addDays(invoiceDate, netDays);
        const daysDifference = differenceInCalendarDays(today, dueDate);

        acc.totalDue += invoice.AmountOwed;
        if (daysDifference > 0) {
          acc.totalOverdue += invoice.AmountOwed;
        }
      });

      return acc;
    },
    { totalDue: 0, totalOverdue: 0 },
  );

  return (
    <Box mt='4' py='4' display='flex'>
      <BalanceCard status='success' title='Open' displayNumber={formatCurrency(totalDue)} />
      <BalanceCard status='alert' title='Overdue' displayNumber={formatCurrency(totalOverdue)} />
    </Box>
  );
}

export function InvoiceReceivableReportTable({ data }: { data: ClientInvoicesDto[] }): React.ReactElement {
  const [expanded, setExpanded] = useState({});

  const tableData: RowData[] = useMemo(() => data.map((client) => ({
    ClientName: client.ClientName,
    Total: (client.Invoices || []).reduce((sum, invoice) => sum + (invoice.AmountOwed || 0), 0),
    SubRows: client.Invoices?.map((invoice) => ({
      ...invoice,
      Overdue: getStatusMessage(invoice.InvoiceDate!, invoice.IsMajorLabel ? 60 : 30).status !== InvoiceStatus.NOT_DUE,
    })) || [],
  })), [data]);

  const columns: ColumnDef<RowData>[] = useMemo(
    () => [
      {
        id: 'expander',
        header: ({ table }) => (() => (
          <Button
            variant='ghost'
            size='sm'
            onClick={() => table.toggleAllRowsExpanded(!table.getIsAllRowsExpanded())}
          >
            <Icon as={table.getIsAllRowsExpanded() ? ChevronDownIcon : ChevronRightIcon} />
          </Button>
        ))(),
        cell: ({ row }) => (() => {
          if (row.getCanExpand()) {
            return (
              <Button
                variant='ghost'
                size='sm'
                onClick={row.getToggleExpandedHandler()}
              >
                <Icon as={row.getIsExpanded() ? ChevronDownIcon : ChevronRightIcon} />
              </Button>
            );
          }
        })(),
        size: 40,
      },
      {
        accessorKey: 'ClientName',
        header: 'Client Name',
        cell: (info) => (info.row.getCanExpand() ? info.getValue() : ''),
        size: 250,
      },
      {
        accessorKey: 'ArtistName',
        header: 'Artist Name',
        cell: (info) => info.getValue() ?? '',
        size: 120,
      },
      {
        accessorKey: 'ProjectName',
        header: 'Project Name',
        cell: (info) => info.getValue(),
        size: 150,
      },
      {
        accessorKey: 'ProjectNumber',
        header: 'Project No.',
        cell: (info) => info.getValue(),
        size: 100,
      },
      {
        accessorKey: 'PurchaseOrderNumber',
        header: 'PO Number',
        cell: (info) => (info.getValue() !== null ? info.getValue() : '-'),
        size: 120,
      },
      {
        accessorKey: 'InvoiceNumber',
        header: 'Invoice No.',
        cell: (info) => info.getValue() ?? '',
        size: 100,
      },
      {
        accessorKey: 'InvoiceDate',
        header: 'Invoice Date',
        cell: (info) => (() => <DateComponent date={info.getValue() as Date} showPlaceholder={false} />)(),
        size: 105,
      },
      {
        accessorKey: 'Amount',
        header: 'Amount',
        cell: (info) => info.getValue() !== undefined && formatCurrency(info.getValue() as number),
        size: 110,
      },
      {
        accessorKey: 'AmountOwed',
        header: 'Balance',
        cell: (info) => info.getValue() !== undefined && formatCurrency(info.getValue() as number),
        size: 110,
      },
      {
        id: 'Status',
        header: 'Status',
        cell: ({ row }) => (
          () => {
            if (row.original.InvoiceDate) {
              return (<InvoiceStatusDisplay invoiceDate={row.original.InvoiceDate ?? ''} netDays={row.original.IsMajorLabel ? 60 : 30} />);
            } return '';
          }
        )(),
        size: 150,
      },
      {
        id: 'Download',
        cell: (info) => (() => !info.row.getCanExpand() && (
          <LinkWithTooltip
            href={`/api/downloads/project-invoice/${info.row.original.ProjectInvoiceIdentifier}`}
            title='Download PDF'
            icon={<Img src={PDFIcon} width='20px' />}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        )
        )(),
        size: 60,
      },
      {
        accessorKey: 'Total',
        header: 'Total Balance',
        cell: (info) => info.getValue() !== undefined && formatCurrency(info.getValue() as number),
        size: 120,
      },
      // Hidden Filter Columns
      {
        id: 'Country',
        accessorKey: 'Country',
        header: 'Country',
        cell: (info) => info.getValue(),
        visible: false,
      },
      {
        id: 'ResourceIdentifier',
        accessorKey: 'ResourceIdentifier',
        header: 'ResourceIdentifier',
        cell: (info) => info.getValue(),
        visible: false,
      },
      {
        id: 'IsMajorLabel',
        accessorKey: 'IsMajorLabel',
        header: 'Label Type',
        cell: (info) => {
          const value = info.getValue();
          return value !== undefined ? value ? 'MajorLabel' : 'IndependentLabel' : null;
        },
      },
      {
        id: 'HasProjectCredit',
        accessorKey: 'HasProjectCredit',
        header: 'HasProjectCredit',
        cell: (info) => info.getValue(),
      },
      {
        id: 'Overdue',
        accessorKey: 'Overdue',
        cell: (info) => info.getValue(),
      },
    ],
    [],
  );

  const table = useReactTable<RowData>({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.SubRows ?? [],
    state: { expanded },
    onExpandedChange: setExpanded,
    filterFromLeafRows: true,
    initialState: {
      columnVisibility: {
        Country: false,
        ResourceIdentifier: false,
        IsMajorLabel: false,
        Overdue: false,
        HasProjectCredit: false,
      },
    },
  });

  const getColumnMaxWidth = (columnId: string) => {
    const column = table.getColumn(columnId);
    return column ? `${column.getSize()}px` : 'auto';
  };

  return (
    <TableContainer minWidth='1545px' minHeight='475px'>
      <InvoiceFilters table={table} tableData={tableData} />
      <TotalAmount table={table} />
      <Table variant='simpleNoStripe'>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr
              key={headerGroup.id}
            >
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  style={{ width: `${header.getSize()}px`, maxWidth: `${header.getSize()}px`, minWidth: `${header.getSize()}px` }}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} backgroundColor={row.getCanExpand() ? 'inherit' : 'gray.700'}>
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  maxWidth={getColumnMaxWidth(cell.column.id)}
                  width={getColumnMaxWidth(cell.column.id)}
                  minWidth={getColumnMaxWidth(cell.column.id)}
                  textOverflow={cell.column.id === 'Actions' ? 'none' : 'ellipsis'}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
