import {
  Avatar, Button, Flex, Stack, Tag, Text,
} from '@chakra-ui/react';
import { startCase } from 'lodash';
import { When } from 'react-if';

import { ProjectContactRoles } from 'api/studioServerTypes';

interface ContactCardProps {
  firstName: string;
  lastName: string;
  identifier: string;
  email: string;
  roles?: string [];
  onAdd?: (identifier: string) => void;
}

export default function ContactCard({
  firstName, lastName, identifier, email, roles, onAdd,
}: ContactCardProps): React.ReactElement {
  return (
    <Flex
      backgroundColor='#0F1118'
      borderRadius='15px'
      p='5'
      gap='20px'
      maxW='372px'
      alignItems='center'
    >
      <Avatar name={`${firstName} ${lastName}`} mr='5px' />
      <Stack spacing='5px'>
        <Text
          key={identifier}
          fontSize='16px'
        >
          {firstName} {lastName}
        </Text>
        <Text fontSize='14px' color='gray.500'> {email}</Text>
        <Flex gap='10px'>
          {roles?.filter((role) => role !== ProjectContactRoles.ProjectAdmin && role !== ProjectContactRoles.ProjectMember).map((role) => (
            <Tag key={role} size='lg' variant='solid' backgroundColor='gray.700'>
              {startCase(role)}
            </Tag>
          ))}
        </Flex>
      </Stack>
      <When condition={!!onAdd}>
        <Button
          onClick={() => onAdd!(identifier)}
          variant='outline'
          borderColor='gray.500'
          w='46px'
          fontSize='14px'
          ml='auto'
          p='5px 10px'
        >
          Add
        </Button>
      </When>
    </Flex>
  );
}
