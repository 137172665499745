import { Input, Text } from '@chakra-ui/react';
import { endOfMonth } from 'date-fns';
import {
  ForwardedRef, forwardRef, useState,
} from 'react';
import DatePicker from 'react-datepicker';
import { When } from 'react-if';
import { useSearchParams } from 'react-router';

import { InvoiceReceivableReportTable } from './components/InvoiceReceivableReportTable';
import { studioServerApi } from 'api/studioServerApi';
import QuerySuspense from 'components/QuerySuspense';
import { useApiQuery } from 'hooks/useApiQuery';

import 'react-datepicker/dist/react-datepicker.css';

const DateSelectorInput = forwardRef(
  ({ value, onClick }: any, ref: ForwardedRef<HTMLInputElement>) => (
    <Input
      onClick={onClick}
      ref={ref}
      value={value}
      placeholder='Select Date'
      readOnly
      cursor='pointer'
      width='230px'
      mt='12px'
    />
  ),
);

export function ReceivableInvoiceReports(): React.ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState<Date | null>(
    searchParams.get('date') ? new Date(searchParams.get('date')!) : null,
  );
  const reportDataQuery = useApiQuery({
    queryKey: ['recievable-reports', date],
    queryFn: () => studioServerApi.GET('/api/reporting/receivable-invoices', {
      params: {
        query: {
          endDateTime: new Date(date!).toISOString(),
        },
      },
    }),
    enabled: !!date,
  });

  return (
    <>
      <Text fontWeight='600' fontSize='22px'>Select Date to Generate Invoice Receivable Report:</Text>
      <DatePicker
        selected={date}
        onChange={(newDate: Date | null) => {
          if (!newDate) return;

          const utcDate = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), 2));
          const endOfMonthUtc = endOfMonth(utcDate);

          const finalUtcDate = new Date(Date.UTC(
            endOfMonthUtc.getUTCFullYear(),
            endOfMonthUtc.getUTCMonth(),
            endOfMonthUtc.getUTCDate(),
            0,
            0,
            0,
          ));

          setDate(finalUtcDate);

          const params = new URLSearchParams(searchParams);
          params.set('date', finalUtcDate.toISOString());
          setSearchParams(params);
        }}
        dateFormat='MM/yyyy'
        showMonthYearPicker
        customInput={<DateSelectorInput />}
      />
      <When condition={!!date}>
        <QuerySuspense queries={[reportDataQuery.queryResult]}>
          <InvoiceReceivableReportTable data={reportDataQuery.apiResult?.data ?? []} />
        </QuerySuspense>
      </When>
    </>
  );
}
