import { Flex, Image, Text } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import UploadIcon from 'assets/icons/upload.svg';
import { IFileWithPath } from 'hooks/useAzureUpload';

export function Dropzone({ height = '224px', onFilesSelected }: { height?: string, onFilesSelected: (files: IFileWithPath[]) => void }): React.ReactElement {
  const onDrop = useCallback((files: File[]) => {
    onFilesSelected(files as IFileWithPath[]);
  }, [onFilesSelected]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
  });

  return (
    <Flex
      direction='column'
      border='2px var(--chakra-colors-gray-400) dashed'
      borderRadius='5px'
      justifyContent='center'
      alignItems='center'
      width='100%'
      height={height}
      backgroundColor='gray.800'
      {...getRootProps({ className: 'dropzone' })}
    >
      <input {...getInputProps()} />
      <Image src={UploadIcon} alt='Upload Icon' w='85px' h='85px' mb='18px' />
      <Text lineHeight='14px' textStyle='paragraphReg' fontFamily='Poppins' fontSize='20px' fontWeight='500'>
        Drag and drop files or folders
      </Text>
    </Flex>
  );
}
