import { Wizard } from 'react-use-wizard';

import { BookingWizardStep, BookingWizardWrapper } from './components/BookingWizardWrapper';
import RegisterClient from './steps/RegisterClient';
import ReviewConfirm from './steps/ReviewConfirm';
import ScheduleSession from './steps/ScheduleSession';
import SelectCompany from './steps/SelectCompany';
import SelectEngineer from './steps/SelectEngineer';
import SelectLineItems from './steps/SelectLineItems';
import SessionDetails from './steps/SessionDetails';

export const steps: BookingWizardStep[] = [
  {
    stepTitle: 'Engineer',
    header: 'Select an engineer',
    key: 'select-engineer-step',
    component: SelectEngineer,
    showEngCard: false,
    showHeaders: true,
    backToStart: true,
    allowBack: false,
  },
  {
    stepTitle: 'Items',
    header: 'Select your order',
    subHeader: '1. Select your mixes and masters',
    key: 'select-line-items-step',
    component: SelectLineItems,
    allowBack: true,
    showHeaders: true,
  },
  {
    stepTitle: 'Comp.',
    header: 'Which company do you want to book your session under?',
    key: 'select-company-step',
    component: SelectCompany,
    showEngCard: true,
    allowBack: true,
    showHeaders: true,
  },
  {
    stepTitle: 'Register',
    header: 'Add a company',
    subHeader: 'Invoices will be issued to the company listed below',
    key: 'register-client-step',
    component: RegisterClient,
    showEngCard: true,
    allowBack: true,
    hidden: true,
    showHeaders: true,
  },
  {
    stepTitle: 'Schedule',
    header: 'Choose a date',
    subHeader: '2. What date will your mixes be ready for mastering?',
    key: 'schedule-session-step',
    component: ScheduleSession,
    showEngCard: true,
    allowBack: true,
    showHeaders: true,
  },
  {
    stepTitle: 'Details',
    header: 'Session details',
    subHeader: '3. Enter Session details',
    key: 'session-details-step',
    component: SessionDetails,
    showEngCard: true,
    allowBack: true,
    showHeaders: true,
  },
  {
    stepTitle: 'Review',
    header: 'Review your session',
    subHeader: '',
    key: 'review-confirm-step',
    component: ReviewConfirm,
    showEngCard: true,
    allowBack: true,
    showHeaders: true,
  },
];

export default function BookingWizard(): React.ReactElement {
  return (
    <Wizard wrapper={<BookingWizardWrapper steps={steps} />}>
      {steps.map((step) => (
        <step.component
          key={step.key}
        />
      ))}
    </Wizard>
  );
}
