import { Flex, Image, Text } from '@chakra-ui/react';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import { useState } from 'react';

import AsyncButton from 'components/form/AsyncButton';
import useTenantContext from 'hooks/useTenantContext';
import useUserContext from 'hooks/useUserContext';

const BUTTON_DISABLE_MS = 25_000;

export function VerifyStep(): React.ReactElement {
  const { logoSrc } = useTenantContext();
  const { user } = useUserContext();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const resendEmail = async (): Promise<void> => {
    if (isButtonDisabled) {
      return;
    }
    setIsButtonDisabled(true);
    const auth = getAuth();
    await sendEmailVerification(auth.currentUser!, {
      url: `${window.location.origin}/booking/sign-up`,
    });
    setTimeout(() => setIsButtonDisabled(false), BUTTON_DISABLE_MS);
  };

  return (
    <Flex alignItems='center' flexDirection='column'>
      <Image src={logoSrc} width='300px' />
      <Flex direction='column' maxWidth='372px' gap='25px'>
        <Text textAlign='center' fontSize='20px' mt='65px'>Verify your email</Text>
        <Text textAlign='center'>In order to register your account, please click the verification link in the email we&apos;ve sent to {user!.EmailAddress}</Text>
        <AsyncButton variant='solid' onClick={resendEmail}>Resend email</AsyncButton>
      </Flex>
    </Flex>
  );
}
