import {
  Flex, Link, Text,
} from '@chakra-ui/react';
import { startCase, toLower } from 'lodash';
import { Else, If, Then } from 'react-if';

import SamplyIcon from 'assets/icons/samply.svg';
import { InfoSet } from 'components/Infoset';
import useUserContext from 'hooks/useUserContext';
import { TransferOverviewResponse } from 'types/api';

function humanizeEnumValue(enumValue: string): string {
  return startCase(toLower(enumValue));
}

enum SamplyStatus {
  LIVE = 'live',
  PENDING = 'pending',
  TRANSFER_CANCELLED = 'transfer-canceled',
  TRANSFER_FAILED = 'transfer-failed',
  TIME_OUT = 'time-out',
}

export function SamplyOverview({ transferOverview }: { transferOverview: TransferOverviewResponse | undefined }): React.ReactElement {
  const { isStudioMember } = useUserContext();

  if (!transferOverview) {
    return (
      <></>
    );
  }

  if (transferOverview.samplyStatus === null || (!isStudioMember && transferOverview.samplyStatus !== 'live' && transferOverview.samplyStatus !== 'pending')) {
    return (
      <></>
    );
  }
  return (
    <InfoSet
      label='Samply Status'
      icon={SamplyIcon}
      value={(
        <If condition={!transferOverview.isExpired && transferOverview.samplyUrl && transferOverview.samplyStatus === 'live'}>
          <Then>
            <If condition={transferOverview.holdStatus.isOnHold && !isStudioMember}>
              <Then>
                <Flex alignItems='center' gap='7px'>
                  <Text whiteSpace='nowrap'>Transfer On Hold</Text>
                </Flex>
              </Then>
              <Else>
                <Flex alignItems='center' gap='7px'>
                  <Link href={transferOverview.samplyUrl || ''} textDecoration='underline' target='_blank' whiteSpace='nowrap'>Stream with Samply</Link>
                </Flex>
              </Else>
            </If>
          </Then>
          <Else>
            <If condition={transferOverview.isExpired || transferOverview.samplyStatus === SamplyStatus.TIME_OUT}>
              <Then>
                <Flex alignItems='center' gap='7px'>
                  <Text whiteSpace='nowrap'>N/A</Text>
                </Flex>
              </Then>
              <Else>
                <Flex alignItems='center' gap='7px'>
                  <Text whiteSpace='nowrap'>{humanizeEnumValue(transferOverview.samplyStatus!)}</Text>
                </Flex>
              </Else>
            </If>
          </Else>
        </If>
      )}
    />
  );
}
