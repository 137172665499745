import {
  Box, Button, Flex, Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import LandingPage from 'assets/landingpage.png';

export default function Splash(): React.ReactElement {
  const navigate = useNavigate();
  return (
    <Box
      backgroundImage={`url(${LandingPage})`}
      height='100vh'
      width='100%'
      backgroundSize='100% auto'
      backgroundRepeat='no-repeat'
      backgroundPosition='center'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <Flex flexDir='column' width='90%' alignItems='center'>
        <Flex flexDir='column' textAlign={['left', 'left']} mb={[4, 0]}>
          <Flex alignItems='flex-start' flexDir='column'>
            <Text fontSize='45' fontWeight='bold'>Master your project</Text>
            <Text fontSize={['md', 'lg']}>Get instant access to rates and turnaround times for Sterling engineers. Schedule and kick-off projects 24 hours a day.</Text>
            <Flex gap='15px' justifyContent='center' alignItems='center' mt='25px'>
              <Button
                onClick={() => void navigate('/booking/sign-options')}
                width='200px'
              >Book a session
              </Button>
              <Button
                variant='outline'
                width='200px'
                height='55px'
                onClick={() => void navigate('/booking/sessions')}
              >My Sessions
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
