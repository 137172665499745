import { Flex, Heading } from '@chakra-ui/react';
import { useParams, useSearchParams } from 'react-router-dom';

import { TransferTaskList } from './components/TransferTaskList';
import { ProjectOverview } from '../project-overview-page/components/ProjectOverview';
import { transferServerApi } from 'api/transfersServerApi';
import { NavigationTabs } from 'components/NavigationTabs';
import QuerySuspense from 'components/QuerySuspense';
import { useApiQuery } from 'hooks/useApiQuery';
import { Upload } from 'pages/transfers/upload-transfer-pages/components/Upload';

export function UploadTransferRequestPage(): React.ReactElement {
  const { transferRequestId } = useParams();
  const [searchParams] = useSearchParams();
  const projectId = Number(searchParams.get('projectId'));
  const transferRequestQuery = useApiQuery({
    queryKey: [transferRequestId],
    queryFn: () => transferServerApi.GET('/api-v2/transfer-requests/{transferRequestId}', {
      params: {
        path: {
          transferRequestId: Number(transferRequestId),
        },
      },
    }),
  });

  return (
    <QuerySuspense queries={[transferRequestQuery.queryResult]}>
      <Flex direction='column' gap='50px' mb='50px'>
        <NavigationTabs hideSearchBar disableSelectedStyling tabName='requests' />
        <Heading fontWeight='700px' fontSize='20px' lineHeight='31px'>Transfer Request</Heading>
        <ProjectOverview projectId={projectId} />
        <Upload uploadRequest={transferRequestQuery.apiResult?.data} />
      </Flex>
      <TransferTaskList />
    </QuerySuspense>
  );
}
