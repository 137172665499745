import {
  Flex,
  TabList, TabPanel, TabPanels, Tabs,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import ArchivesTable from './components/ArchivesTable';
import { ArchivesUploadPage } from '../archive-upload/ArchivesUploadPage';
import { UploadManagerIcon } from '../components/UploadManagerIcon';
import EmptyStateIcon from 'assets/icons/inbox.svg';
import { StudiumTableTab } from 'components/StudiumTableTab';
import { SearchBar } from 'components/table/SearchBar';
import useUserContext from 'hooks/useUserContext';
import { InboxIcon } from 'pages/transfers/projects-page/components/icons/InboxIcon';

const tabMap: Record<string, number> = {
  list: 0,
  upload: 1,
};
export default function ArchivesPage({ tabName }: { tabName: string }): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  function onEnter(searchTerm: string): void {
    // set url search param to searchTerm
    if (!searchTerm) params.delete('search');
    else params.set('search', searchTerm);
    void navigate(`${location.pathname}?${params.toString()}`);
  }

  const { user } = useUserContext();

  const emptyTableState = {
    message: 'You have no archived transfers yet',
    icon: EmptyStateIcon,
  };

  return (
    <Tabs
      index={tabMap[tabName || 'list']}
      isLazy
      lazyBehavior='unmount'
      variant='unstyled'
    >
      <Flex
        px='16px'
        flexDirection={['column', 'column', 'column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'flex-start', 'flex-start', 'center']}
        gap='4'
        justifyContent='space-between'
      >
        <TabList
          as={Flex}
          gap='2'
        >
          <RouterLink to='/archives'>
            <StudiumTableTab icon={InboxIcon()}>Archives</StudiumTableTab>
          </RouterLink>
          <RouterLink to='/archives/upload'>
            <StudiumTableTab icon={UploadManagerIcon()}>Upload Manager</StudiumTableTab>
          </RouterLink>
        </TabList>
        <Flex
          flexDirection='row'
          w='538px'
        >
          <SearchBar onEnter={onEnter} />
        </Flex>
      </Flex>
      <TabPanels>
        <TabPanel>
          <ArchivesTable path={`/file-api/archives/${user?.AccountNumber}`} emptyTableState={emptyTableState} />
        </TabPanel>
        <TabPanel>
          <ArchivesUploadPage />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
