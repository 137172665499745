import {
  Box,
  Center,
  Flex, FormControl, FormErrorMessage, HStack, Image, Link, PinInput,
  PinInputField, Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  FormProvider, UseFormSetError, useForm,
} from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import { firebaseAuth, signOut } from 'api/auth';
import Warning from 'assets/icons/warning.svg';
import AsyncButton from 'components/form/AsyncButton';

export interface IMfa {
  pin1: string
  pin2: string
  pin3: string
  pin4: string
  pin5: string
  pin6: string
}

export function MultiFactorForm({ submitCallback }: {
  submitCallback?: (mfaCode: string, setError: UseFormSetError<IMfa>) => Promise<void>;
}): React.ReactElement {
  const formMethods = useForm<IMfa>();

  const {
    register, handleSubmit, formState, setError, watch,
  } = formMethods;

  const individualPins = watch(['pin1', 'pin2', 'pin3', 'pin4', 'pin5', 'pin6']);
  const [mfaCode, setMfaCode] = useState('');
  useEffect(() => {
    const combinedPin = individualPins.join('');
    setMfaCode(combinedPin);
  }, [individualPins]);

  const { errors } = formState;
  async function validateForm(): Promise<void> {
    if (mfaCode.length === 0) {
      setError('pin1', { type: 'required', message: 'Code is required' });
      return;
    }
    if (mfaCode.length !== 6) {
      setError('pin1', { type: 'required', message: 'Code must be 6 digits' });
      return;
    }
    await submitCallback?.(mfaCode, setError);
  }
  return (
    <>

      <Text h='50px' as='h5' fontSize='24px' lineHeight='36px' fontWeight='600' fontFamily='Poppins'>
        Verify Your Identity
      </Text>
      <Text as='h5' fontSize='18px' lineHeight='36px' fontWeight='600' fontFamily='Poppins' noOfLines={1} overflowWrap='normal' marginBottom='14px'>
        {firebaseAuth.currentUser?.email}
      </Text>
      <Text textStyle='paragraph' textAlign='center' fontSize='14px' lineHeight='21px' fontWeight='400'>
        Enter the 6-digit code generated by your authenticator app
      </Text>
      <FormControl isInvalid={!!errors.pin1?.message}>
        <FormProvider {...formMethods}>
          <form>
            <Center>
              <Flex
                direction='column'
                w='300px'
              >
                <HStack mt='15px' mb='25px'>
                  <PinInput
                    otp
                    size='lg'
                    placeholder=' '
                  >
                    <PinInputField {...register('pin1')} />
                    <PinInputField {...register('pin2')} />
                    <PinInputField {...register('pin3')} />
                    <Box w='20px' border='none' />
                    <PinInputField {...register('pin4')} />
                    <PinInputField {...register('pin5')} />
                    <PinInputField {...register('pin6')} />
                  </PinInput>
                </HStack>
                <FormErrorMessage mt='10px' gap='10px'>
                  <Image src={Warning} w='11px' h='10px' />
                  <Text pt='5px' textColor='gold.1000'>{errors.pin1?.message} </Text>
                </FormErrorMessage>
                <Flex alignContent='center'>
                  <AsyncButton
                    formState={formState}
                    variant='login'
                    my='15px'
                    type='submit'
                    onClick={handleSubmit(validateForm)}
                    backgroundColor='gold.1000'
                    color='black'
                  >
                    Verify
                  </AsyncButton>
                </Flex>
              </Flex>
            </Center>
          </form>
        </FormProvider>
      </FormControl>
      <Link textStyle='paragraph' fontWeight='400' as={RouterLink} onClick={() => signOut()}>
        Back
      </Link>
    </>
  );
}
