import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import ViewPOIcon from '../../assets/icons/view-po.svg';
import { studioServerApi } from 'api/studioServerApi';
import QuerySuspense from 'components/QuerySuspense';
import { useApiQuery } from 'hooks/useApiQuery';
import useTenantContext from 'hooks/useTenantContext';

export default function PurchaseOrderConfirmed(): React.ReactElement {
  const { purchaseOrderApprovalRequestIdentifier } = useParams();
  const { logoSrc } = useTenantContext();
  const purchaseOrderApprovalRequestQuery = useApiQuery({
    queryKey: ['portal', 'purchase-order-request', purchaseOrderApprovalRequestIdentifier!],
    queryFn: () => studioServerApi.GET(
      '/api/portal/purchase-order-request/{purchaseOrderApprovalRequestIdentifier}',
      { params: { path: { purchaseOrderApprovalRequestIdentifier: purchaseOrderApprovalRequestIdentifier! } } },
    ),
  });

  return (
    <QuerySuspense queries={[purchaseOrderApprovalRequestQuery.queryResult]}>
      <Flex
        padding='20px'
        direction='column'
        align='center'
        w='400px'
        mx='auto'
        mt='50px'
      >
        <Box width='400px' padding='20px'>
          <Flex direction='column' gap='25px'>
            <Flex justifyContent='center' mb='15px'>
              <Image src={logoSrc} width='300px' />
            </Flex>
            <Heading
              fontSize='24px'
              textAlign='center'
            >Purchase Order Submitted
            </Heading>
            <Text>You approved this purchase order request on {format(purchaseOrderApprovalRequestQuery.apiResult?.data?.MostRecentApprovalByContactDate || new Date(), 'PPPPpp')}.</Text>
            <Flex>
              <Text mr='7px' fontSize='18px'>Purchase Order Number:</Text>
              <Text fontWeight='900' fontSize='18px'>{purchaseOrderApprovalRequestQuery.apiResult?.data?.MostRecentApprovalByContactPurchaseOrderNumber}</Text>
            </Flex>
            <Button variant='back' as={Link} to={purchaseOrderApprovalRequestQuery.apiResult?.data?.DownloadPdfUrl!} target='_blank'>
              <Image w='15px' h='15px' marginRight='7px' src={ViewPOIcon} />
              View request
            </Button>
          </Flex>
        </Box>
      </Flex>
    </QuerySuspense>
  );
}
