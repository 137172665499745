import { Flex, Text } from '@chakra-ui/react';

type ValueWithLabelProps = {
  label: string;
  value: string;
  labelColor?: string;
  valueColor?: string;
  fontSize?: string;
  marginRight?: string;
};

export function ValueWithLabel({
  label,
  value,
  labelColor = 'gray.400',
  valueColor = 'inherit',
  fontSize = '14px',
  marginRight = '8px',
}: ValueWithLabelProps): React.ReactElement {
  return (
    <Flex>
      <Text color={labelColor} fontSize={fontSize} mr={marginRight} whiteSpace='nowrap'>
        {label}
      </Text>
      <Text fontSize={fontSize} color={valueColor}>
        {value}
      </Text>
    </Flex>
  );
}
