import { CalendarIcon } from '@chakra-ui/icons';
import {
  Box, Button, Flex, Input, InputGroup, Spinner, Text,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import {
  addDays, addMonths, format,
} from 'date-fns';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Controller, useForm } from 'react-hook-form';
import { useWizard } from 'react-use-wizard';

import { studioServerApi } from 'api/studioServerApi';
import { LineItem } from 'api/studioServerTypes';
import ErrorDisplay from 'components/error/ErrorDisplay';
import AsyncButton from 'components/form/AsyncButton';
import { useBookingContext } from 'contexts/booking/BookingContext';

type ScheduleSessionFormValues = {
  date: string;
};

const postBookableDate = async (dateData: { date: string, lineItems: LineItem[], resourceIdentifier: string }) => {
  const response = await studioServerApi.POST('/api/resources/next-bookable-date', {
    body: {
      LineItems: dateData.lineItems,
      ResourceIdentifier: dateData.resourceIdentifier,
      UploadDate: dateData.date,
    },
  });
  return response.data!;
};

function showDatePicker(e:EventTarget):void {
  if (isMobile) return;
  const pickerInput = e as any;
  pickerInput.showPicker();
}

function formatForDateInput(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

export default function ScheduleSession(): React.ReactElement {
  const {
    resource,
    lineItems,
    setMastersByDate,
    mastersByDate,
    uploadDate,
    setUploadDate,
  } = useBookingContext();
  const { nextStep } = useWizard();

  const altDateInputType = isMobile ? 'date' : 'text';

  const {
    handleSubmit,
    control,
    formState,
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm<ScheduleSessionFormValues>({
    defaultValues: {
      date: uploadDate,
    },
  });

  const [isChecking, setIsChecking] = useState(false);

  const { mutateAsync } = useMutation({
    mutationKey: ['/api/resources/next-bookable-date'],
    mutationFn: postBookableDate,
  });

  async function onSubmit(data: ScheduleSessionFormValues): Promise<void> {
    await nextStep();
  }

  async function checkAvailability(date: string | undefined): Promise<void> {
    if (!date) {
      setError('date', { type: 'required' });
      return;
    }
    if (formState.errors.date) return;

    const startTime = new Date().getTime();

    const endOfUploadDate = `${date} 00:00:00`;
    setIsChecking(true);
    setMastersByDate(undefined);
    const mastersDateResp = await mutateAsync({
      date: endOfUploadDate,
      lineItems: lineItems!,
      resourceIdentifier: resource?.ResourceIdentifier!,
    });
    setTimeout(() => {
      setMastersByDate(mastersDateResp);
      setIsChecking(false);
      setValue('date', date);
    }, startTime + 4000 - new Date().getTime());
  }

  const minimumDate = formatForDateInput(addDays(new Date(), 1));
  const maximumDate = formatForDateInput(addMonths(addDays(new Date(), -1), 3)); // 3 months less 1 day

  return (
    <Box>
      <Box>
        <form
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name='date'
            control={control}
            rules={{
              required: 'Date is required',
              validate: {
                dateIsNotInThePast: (date) => date >= minimumDate || 'Date cannot be in the past',
                dateNotTooFarInTheFuture: (date) => maximumDate >= date || 'Date cannot be more than 3 months in the future',
              },
            }}
            render={({ field }) => (
              <InputGroup sx={{ display: 'flex', flexDir: 'column' }}>
                <Input
                  {...field}
                  type={altDateInputType}
                  // https://stackoverflow.com/questions/68480474/how-to-set-placeholder-in-input-type-date-react
                  onFocus={(e) => {
                    e.target.type = 'date';
                    showDatePicker(e.target);
                  }}
                  onClick={(e) => {
                    showDatePicker(e.target);
                  }}
                  onBlurCapture={(e) => {
                    e.target.type = altDateInputType;
                  }}
                  onChange={(e) => {
                    setMastersByDate(undefined);
                    setUploadDate(e.target.value);
                  }}
                  isInvalid={!!formState.errors.date}
                  mb='4'
                  mt='15px'
                  min={minimumDate}
                  max={maximumDate}
                  placeholder={isMobile ? undefined : 'Select upload date'}
                  value={uploadDate || ''}
                />
                <Button onClick={async () => {
                  clearErrors('date');
                  await checkAvailability(uploadDate);
                }}
                >
                  Check availability
                </Button>
              </InputGroup>
            )}
          />
          <ErrorDisplay error={formState.errors.date} />

          <Flex alignItems='flex-start' justifyContent='center' mb='4' direction='column'>
            {isChecking && (
              <Box display='flex' justifyContent='center' alignItems='center' w='100%' h='100px' backgroundColor='#0F1118' borderRadius='10px' mt='25px' mb='25px'>
                <Flex alignItems='center' gap='2'>
                  <Spinner mr='2' />
                  <Text>Checking for availability...</Text>
                </Flex>
              </Box>
            )}
            {!isChecking && mastersByDate && uploadDate && (
              <Box display='flex' flexDir='column' justifyContent='center' alignItems='center' w='100%' h='100px' backgroundColor='#0F1118' borderRadius='10px' mb='25px' mt='25px'>
                <Text justifyContent='flex-start'>Masters will be ready by end of the day</Text>
                <Flex alignItems='center' gap='2'>
                  <CalendarIcon />
                  <Text fontSize='22px'>{format(new Date(mastersByDate), 'MMM dd, yyyy')}</Text>
                </Flex>
              </Box>
            )}
            {mastersByDate && uploadDate && (
              <AsyncButton type='submit' formState={formState} colorScheme='teal' width='100%' height='54' onClick={handleSubmit(onSubmit)}>Continue</AsyncButton>
            )}
          </Flex>
        </form>
      </Box>
    </Box>
  );
}
