import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Box, Button, Container, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Image, Menu,
  MenuButton, MenuItem, MenuList, Spacer, Tab, TabIndicator, TabList, Tabs, useDisclosure, useMediaQuery,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { When } from 'react-if';
import { Link as RouterLink } from 'react-router-dom';

import AuthorizeDisplay from './AuthorizeDisplay';
import { Profile, ProfileProps } from './Profile';
import { signOut } from 'api/auth';
import SterlingLogo from 'assets/logos/sterling-logo-light.svg';
import { Roles } from 'contexts/user/claims';
import useIsSpecificRoute from 'hooks/useIsSpecificRoute';
import useTenantContext from 'hooks/useTenantContext';
import useUserContext from 'hooks/useUserContext';
import { IUserProfileInfo } from 'types/studio-server';

interface MenuLinksProps {
  Component: typeof RouterLink | typeof MenuItem;
}

function MenuLinks({ Component }: MenuLinksProps): React.ReactElement {
  const {
    isBookingRoute,
  } = useIsSpecificRoute();
  const { menuLinks } = useUserContext();
  const { features } = useTenantContext();

  return (
    <>
      {menuLinks.subscriptions && features.subscriptions && (
      <Component as={RouterLink} to='/transfers/subscriptions'>Subscriptions</Component>
      )}
      {menuLinks.integrations && features.integrations && (
      <Component as={RouterLink} to='/integrations'>Integrations</Component>
      )}
      {menuLinks.receivableReports && features.receivableReports && (
      <Component as={RouterLink} to='/reports/receivable-invoices'>Receivable Reports</Component>
      )}
      {menuLinks.securitySettings && features.securitySettings && !isBookingRoute && (
      <Component as={RouterLink} to='/security-settings'>Security Settings</Component>
      )}
      {menuLinks.bookingSignUp && features.bookingSignUp && (
      <Component as={RouterLink} to='/booking/sign-up'>Sign Up</Component>
      )}
      {menuLinks.login && features.login && (
      <Component as={RouterLink} to='/login'>Login</Component>
      )}
      {menuLinks.paymentSettings && features.paymentSettings && (
      <Component as={RouterLink} to='/booking/payment-settings'>Payment Settings</Component>
      )}
      {menuLinks.securitySettings && features.securitySettings && isBookingRoute && (
      <Component as={RouterLink} to='/booking/security-settings'>Security Settings</Component>
      )}
      {menuLinks.logout && features.logout && (
      <Component as={RouterLink} to='/' onClick={() => signOut('', isBookingRoute ? 'booking' : undefined)}>Logout</Component>
      )}
    </>
  );
}

interface DrawerMenuProps {
  isOpen: boolean;
  onClose: () => void;
  user: IUserProfileInfo | undefined;
}

function DrawerMenu({
  isOpen, onClose, user,
}: DrawerMenuProps): React.ReactElement {
  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader display='none'>Navigation</DrawerHeader>
          <DrawerBody as={Flex} flexDir='column' gap='10' justifyContent='center' marginLeft='15'>
            <MenuLinks Component={RouterLink} />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}

interface HeaderTabsProps {
  tabIndex: number;
}

function HeaderTabs({ tabIndex }: HeaderTabsProps) {
  return (
    <Tabs
      index={tabIndex}
      position='relative'
      variant='unstyled'
      isLazy
      lazyBehavior='unmount'
    >
      <TabList gap='50px'>
        <Tab
          as={RouterLink}
          px='0px'
          _hover={{ color: 'gray.400' }}
          _selected={{ color: 'gold.1000' }}
          to='/transfers/inbox'
          fontWeight='600'
          fontSize='16px'
        >
          Transfers
        </Tab>
        <AuthorizeDisplay role={Roles.StudioMember}>
          <Tab
            as={RouterLink}
            px='0px'
            _hover={{ color: 'gray.400' }}
            _selected={{ color: 'gold.1000' }}
            to='/archives'
            fontWeight='600'
            fontSize='16px'
          >
            Archives
          </Tab>
        </AuthorizeDisplay>
      </TabList>
      <TabIndicator
        position='relative'
        mt='0px'
        height='0px'
        bg='gold.1000'
        _before={{
          content: '""',
          position: 'absolute',
          top: 'auto',
          left: '50%',
          transform: 'translateX(-50%)',
          height: '5px',
          width: '48px !important',
          bg: 'gold.1000',
          opacity: '1',
          borderRadius: '31px',
          transformOrigin: 'left',
        }}
      />
    </Tabs>
  );
}

interface DesktopMenuProps {
  profileProps: ProfileProps;
  user: IUserProfileInfo | undefined;
}

function DesktopMenu({
  profileProps,
  user,
}: DesktopMenuProps) {
  return (
    <>
      <MenuButton>
        <Profile {...profileProps} />
      </MenuButton>
      <MenuList textStyle='paragraphReg'>
        <MenuLinks Component={MenuItem} />
      </MenuList>
    </>
  );
}

interface MobileMenuProps extends DesktopMenuProps {
  onOpen: () => void;
  isOpen: boolean;
  onClose: () => void;
}

function MobileMenu({
  onOpen,
  profileProps,
  isOpen,
  onClose,
  user,
}: MobileMenuProps) {
  return (
    <>
      <Flex alignItems='center'>
        <Profile {...profileProps} />
        <Button onClick={onOpen} backgroundColor='gray.700' color='white' marginLeft='7px'>
          <HamburgerIcon />
        </Button>
      </Flex>
      <DrawerMenu
        isOpen={isOpen}
        onClose={onClose}
        user={user}
      />
    </>
  );
}

export default function Header(): React.ReactElement {
  const { user } = useUserContext();
  const { features } = useTenantContext();
  const userLogo = user?.AccountLogoLight;
  const avatar = user?.Avatar;
  const {
    isArchivesRoute, isTransfersRoute, isReportRoute,
  } = useIsSpecificRoute();
  const tabIndex = useMemo(() => (isArchivesRoute ? 1 : 0), [isArchivesRoute]);
  const [isLessThan700] = useMediaQuery('(max-width: 700px)');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const profileProps: ProfileProps = {
    displayName: `${user?.FirstName} ${user?.LastName}`,
    avatarSrc: avatar,
    email: user?.EmailAddress,
    width: 'fit-content',
    height: '65px',
    borderRadius: '66px',
    backgroundColor: isLessThan700 ? 'transparent' : 'gray.800',
    boxShadow: isLessThan700 ? 'none' : '0px 15px 54px rgba(0, 0, 0, 0.35)',
  };

  const MenuComponent = isLessThan700 ? MobileMenu : DesktopMenu;

  const currentRoute = `/${window.location.pathname.split('/')[1]}`;

  return (
    <Box position='sticky' top='0' zIndex='1000' backgroundColor='gray.900' boxShadow='sm'>
      <Container as={Flex} px='22px' mt='18px' mb='18px' minW='350px' maxW='1920px' alignItems='center'>
        <Box as={RouterLink} to={currentRoute}>
          <Image width='159px' mr='50px' src={userLogo ? `data:image/png;base64, ${userLogo}` : SterlingLogo} />
        </Box>
        <When condition={(isTransfersRoute || isArchivesRoute || isReportRoute) && !isLessThan700 && features.tenantHasTransfers}>
          <HeaderTabs tabIndex={tabIndex} />
        </When>
        <Spacer />
        <Box>
          {user ? (
            <Menu isLazy>
              <MenuComponent
                onOpen={onOpen}
                profileProps={profileProps}
                isOpen={isOpen}
                onClose={onClose}
                user={user}
              />
            </Menu>
          ) : (
            <Profile {...profileProps} />
          )}
        </Box>
      </Container>
    </Box>
  );
}
