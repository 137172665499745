import { Checkbox } from '@chakra-ui/react';
import {
  Dispatch, HTMLProps, SetStateAction, useEffect, useRef,
} from 'react';

import { FileRow, getFileIds } from 'utils/file';

export function IndeterminateCheckbox({
  indeterminate,
  allChecked,
  disabled = false,
  onChange,
  fileId,
  setSelectedFiles,
  row,
}: { indeterminate?: boolean, allChecked: boolean, disabled?: boolean, fileId: number, setSelectedFiles: Dispatch<SetStateAction<number[]>>, row: FileRow }
& HTMLProps<HTMLInputElement>): React.ReactElement {
  const ref = useRef<HTMLInputElement>(null!);
  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !allChecked && indeterminate;
      ref.current.disabled = disabled;
    }
  }, [ref, indeterminate, allChecked, fileId, disabled]);

  function handleChange(e: React.FormEvent<HTMLInputElement>): void {
    const fileIds = getFileIds(row);

    if (!allChecked) {
      setSelectedFiles((prev: number[]) => [...prev, ...fileIds]);
    } else {
      setSelectedFiles((prev) => prev.filter((f) => !fileIds.includes(f)));
    }
    if (onChange) {
      onChange(e);
    }
  }

  return (
    <Checkbox
      borderRadius='2px'
      isChecked={allChecked}
      colorScheme='gold.1000'
      iconColor='white'
      background={allChecked ? 'gold.1000' : 'inherit'}
      borderColor={allChecked ? 'gold.1000' : 'gray.500'}
      isIndeterminate={indeterminate}
      onChange={handleChange}
      ref={ref}
    />
  );
}
