import {
  Flex,
  Img,
  Link,
  Text,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { Else, If, Then } from 'react-if';

import { ValueWithLabel } from './ValueWithLabel';
import PaidIcon from 'assets/icons/invoice-status-icons/paid-icon.svg';
import PartiallyPaidIcon from 'assets/icons/invoice-status-icons/partially-paid-icon.svg';
import UnpaidIcon from 'assets/icons/invoice-status-icons/unpaid-icon.svg';
import ViewInvoiceIcon from 'assets/icons/invoice-status-icons/view-invoice-icon.svg';
import { MySterlingInvoice } from 'types/studio-server';

export function InvoiceDisplay({ invoice }: { invoice: MySterlingInvoice }): React.ReactElement {
  const invoiceAmount = invoice.InvoiceAmount || 0;
  const amountPaid = invoice.AmountPaid || 0;
  const balanceOwed = invoiceAmount - amountPaid;

  const isPaid = amountPaid >= invoiceAmount;
  const isPartiallyPaid = amountPaid > 0 && amountPaid < invoiceAmount;
  const isUnPaid = amountPaid === 0;

  const netDays = invoice.IsMajorLabel ? 60 : 30;
  const paymentDueDate = format(
    new Date(new Date(invoice.InvoiceDate!).setDate(new Date(invoice.InvoiceDate!).getDate() + netDays)),
    'MMM d, yyyy',
  );

  return (
    <Flex
      gap='28px'
      direction='column'
      borderRadius='12px'
      borderColor='gray.400'
      borderWidth='1px'
      padding='16px 20px'
      maxWidth='400px'
    >
      <Flex direction='column' gap='6px'>
        <Flex alignItems='center'>
          <Text color='gray.400' fontSize='14px' mr='8px'>
            Invoice number
          </Text>
          <Text fontSize='14px'>{invoice.InvoiceNumber}</Text>
          <If condition={isPaid}>
            <Then>
              <Img src={PaidIcon} alt='Paid' ml='auto' />
            </Then>
          </If>
          <If condition={isPartiallyPaid}>
            <Then>
              <Img src={PartiallyPaidIcon} alt='Partially Paid' ml='auto' />
            </Then>
          </If>
          <If condition={isUnPaid}>
            <Then>
              <Img src={UnpaidIcon} alt='Unpaid' ml='auto' />
            </Then>
          </If>
        </Flex>

        <ValueWithLabel label='Session name' value={invoice.ProjectName || 'N/A'} />
        <ValueWithLabel label='Artist name' value={invoice.ArtistName || 'N/A'} />

        <If condition={isPaid}>
          <Then>
            <ValueWithLabel label='Invoice amount' value={`$${invoiceAmount.toFixed(2)}`} />
            <ValueWithLabel label='Amount paid' value={`$${amountPaid.toFixed(2)}`} />
            <ValueWithLabel
              label='Date of payment'
              value={
                invoice.DateOfPayment
                  ? format(new Date(invoice.DateOfPayment), 'MMM d, yyyy')
                  : 'N/A'
              }
            />
          </Then>
          <Else>
            <ValueWithLabel label='Balance owing' value={`$${balanceOwed.toFixed(2)}`} />
            <ValueWithLabel label='Invoice amount' value={`$${invoiceAmount.toFixed(2)}`} />
            <ValueWithLabel label='Payment due' value={paymentDueDate} />
          </Else>
        </If>
      </Flex>

      <If condition={isPaid}>
        <Then>
          <Link
            href={`/api/downloads/project-invoice/${invoice.ProjectInvoiceIdentifier}`}
            bg='gray.800'
            color='white'
            border='1px solid'
            borderColor='gray.400'
            borderRadius='6px'
            height='auto'
            py='13px'
            px='15px'
            display='flex'
            alignItems='center'
            justifyContent='center'
            _hover={{ bg: 'gray.700', textDecoration: 'none' }}
          >
            <Img src={ViewInvoiceIcon} display='flex' width='18px' mr='8px' />
            View Invoice
          </Link>
        </Then>
        <Else>
          <Flex gap='8px'>
            <Link
              href={`/portal/pay-invoice/${invoice.ContactIdentifier}/${invoice.ProjectInvoiceIdentifier}/`}
              bg='gold.1000'
              color='black'
              borderRadius='6px'
              flex='1'
              height='auto'
              py='13px'
              display='flex'
              alignItems='center'
              justifyContent='center'
              _hover={{ bg: 'darkGold.1000', textDecoration: 'none' }}
            >
              Pay now
            </Link>
            <Link
              href={`/api/downloads/project-invoice/${invoice.ProjectInvoiceIdentifier}`}
              bg='gray.700'
              color='white'
              borderRadius='6px'
              height='auto'
              py='13px'
              px='15px'
              minWidth='auto'
              display='flex'
              alignItems='center'
              justifyContent='center'
              _hover={{ bg: 'gray.600', textDecoration: 'none' }}
            >
              <Img src={ViewInvoiceIcon} width='18px' />
            </Link>
          </Flex>
        </Else>
      </If>
    </Flex>
  );
}
