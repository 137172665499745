import { If, Then } from 'react-if';
import { Navigate, Route } from 'react-router';

import PrivateRoute from '../components/PrivateRoute';
import PageTemplate from 'components/page-template/PageTemplate';
import useUserContext from 'hooks/useUserContext';
import { ReceivableInvoiceReports } from 'pages/reports/ReceivableInvoice/ReceivableInvoiceReports';

export default function useReportRoutes(): JSX.Element {
  const { isLoggedIn } = useUserContext();

  return (
    <>
      {/* Replace With Reports Page when It exists
      Without this the Nav "Home" Button doesn't work */}
      <Route
        path='/reports'
        element={(
          <If condition={isLoggedIn}>
            <Then>
              <Navigate to='/transfers' />
            </Then>
          </If>
        )}
      />
      <Route
        path='/reports/*'
        element={(
          <PageTemplate>
            <PrivateRoute allowContact />
          </PageTemplate>
        )}
      >
        <Route
          path='receivable-invoices'
          element={<ReceivableInvoiceReports />}
        />
      </Route>
    </>
  );
}
