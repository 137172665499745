import { Flex } from '@chakra-ui/react';
import { useEffect } from 'react';

import GoogleCalendar from 'assets/integrations/google-calendar.svg';
import IntegrationCard from 'components/IntegrationCard';
import Sheet from 'components/Sheet';
import useGoogleConnect from 'hooks/integrations/useGoogleConnect';
import useUserContext from 'hooks/useUserContext';

export default function IntegrationsPage(): React.ReactElement {
  const { user } = useUserContext();

  const [googleLogin, codeResponse] = useGoogleConnect();

  useEffect(() => {
    if (codeResponse) window.location.reload();
  }, [codeResponse]);

  const googleIsConnected = !!user?.IsGoogleConnected;

  return (
    <Sheet
      title='Integrations'
    >
      <Flex direction={['column', 'column', 'row']} gap='50px' align='center'>
        <IntegrationCard
          title='Google Calendar'
          description='Connect with Google Calendar'
          image={GoogleCalendar}
          onSubmit={() => googleLogin()}
          isDisabled={googleIsConnected}
        />
      </Flex>
    </Sheet>
  );
}
