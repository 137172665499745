import {
  ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { ITransferTask } from 'pages/transfers/upload-transfer-pages/components/TransferTask';

type TransferTaskState = Omit<ITransferTask, 'onUploadComplete' | 'isMyTurn'>;

export interface ITransferTasksState {
  transferTasks: TransferTaskState[]
  pushTask: (task: TransferTaskState) => void
  pushToQueue: (id: string) => void
  onUploadComplete: (id: string) => void
  getActiveId: () => (string | undefined)
}

export const TransferContext = createContext<ITransferTasksState>({
  transferTasks: [],
  pushTask: () => {},
  pushToQueue: () => {},
  onUploadComplete: () => {},
  getActiveId: () => undefined,
});

export function TransferProvider({ children }: { children: ReactNode }): React.ReactElement {
  const [transferTasks, setTransferTasks] = useState<TransferTaskState[]>([]);
  const [uploadTransferQueue, setUploadTransferQueue] = useState<string[]>([]);
  const location = useLocation();

  const onUploadComplete = useCallback((id: string): void => {
    setUploadTransferQueue(uploadTransferQueue?.filter((queueId) => queueId !== id));
  }, [uploadTransferQueue]);

  const pushTask = useCallback((task: TransferTaskState): void => {
    setTransferTasks([...transferTasks, task]);
  }, [transferTasks]);

  const pushToQueue = useCallback((id: string): void => {
    setUploadTransferQueue([...uploadTransferQueue, id]);
  }, [uploadTransferQueue]);

  const getActiveId = useCallback((): string | undefined => uploadTransferQueue[0], [uploadTransferQueue]);

  // Clear tasks when navigating away from a page.
  useEffect(() => {
    setTransferTasks([]);
    setUploadTransferQueue([]);
  }, [location.pathname]);

  const state: ITransferTasksState = useMemo(() => ({
    transferTasks,
    pushTask,
    pushToQueue,
    onUploadComplete,
    getActiveId,
  }), [transferTasks, getActiveId, onUploadComplete, pushTask, pushToQueue]);

  return (
    <TransferContext.Provider value={state}>
      {children}
    </TransferContext.Provider>
  );
}

export function useTransfers(): ITransferTasksState {
  return useContext(TransferContext);
}
