export function SentIcon(): React.ReactElement {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 20 21'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        clipRule='evenodd'
        // eslint-disable-next-line max-len
        d='M18.1106 2.29718L2.0293 7.65762L8.30843 11.0387L13.0208 6.32632L14.0815 7.38698L9.36909 12.0994L12.7502 18.3785L18.1106 2.29718ZM7.8905 12.5173L0.604922 8.59428C-0.282445 8.11647 -0.169629 6.80946 0.786476 6.49076L18.3003 0.6528C19.1994 0.353125 20.0547 1.20843 19.755 2.10745L13.917 19.6213C13.5983 20.5774 12.2913 20.6902 11.8135 19.8029L7.8905 12.5173Z'
      />
    </svg>
  );
}
