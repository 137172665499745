import {
  Flex, FormControl, FormErrorMessage, Heading, Image, Input, Link, Text, useToast,
} from '@chakra-ui/react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import {
  FormProvider,
  useForm,
} from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
  PendingCredentialSessionStorageKeys,
  firebaseAuth,
  formatFireBaseError,
  getPendingCredential,
  isFireBaseError,
  linkAccounts,
  signOut,
} from 'api/auth';
import WarningIcon from 'assets/icons/warning.svg';
import AsyncButton from 'components/form/AsyncButton';
import useTenantContext from 'hooks/useTenantContext';
import { logError } from 'utils/error';

interface ILinkAccount {
  password: string;
}

export default function LinkAccount(): React.ReactElement {
  const { logoSrc } = useTenantContext();
  const formMethods = useForm<ILinkAccount>();
  const {
    register, handleSubmit, formState, setError,
  } = formMethods;
  const navigate = useNavigate();
  const { errors } = formState;
  const toast = useToast();

  async function onSubmit({ password }: ILinkAccount): Promise<void> {
    const userEmail = sessionStorage.getItem(PendingCredentialSessionStorageKeys.emailForSignIn);

    if (!userEmail) {
      toast({
        title: 'Error',
        description: 'Couldn\'t find email in local storage. Return to Login and sign-in with Microsoft to continue.',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });

      return;
    }
    try {
      const result = await signInWithEmailAndPassword(firebaseAuth, userEmail, password);

      if (sessionStorage.getItem(PendingCredentialSessionStorageKeys.pendingCred) !== null) {
        const pendingCred = getPendingCredential();
        if (!pendingCred) {
          logError(new Error('No pending credential found'));
        } else {
          await linkAccounts({
            user: result.user,
            oauthCredential: pendingCred,
          });
        }
      }

      await signOut();
      void navigate('/login');
    } catch (e: any) {
      if (isFireBaseError(e)) {
        setError('password', { type: 'manual', message: formatFireBaseError(e) });
      }
      logError(e);
      throw e;
    }
  }

  return (
    <Flex width={['320px', '360px']} direction='column' mx='auto' align='center' pt='197px'>
      <Image src={logoSrc} w='300px' />
      <Flex direction='column' mt='50px' mb='50px' gap='33px'>
        <Heading textAlign='start' fontSize='24px' fontWeight='600px' color='white'>Connect Microsoft Account</Heading>
        <Text color='white' fontSize='16px' lineHeight='20px' fontWeight='400'>Please enter your Sterling Sound password to allow sign-in with your Microsoft account</Text>
        <FormControl isInvalid={!!errors.password?.message}>
          <FormProvider {...formMethods}>
            <form>
              <Input
                autoFocus
                type='password'
                placeholder='🔒 Sterling Sound Password'
                {...register('password', { required: 'Password cannot be empty' })}
              />
              <FormErrorMessage mt='10px' gap='10px' alignItems='baseline'>
                <Image src={WarningIcon} boxSize='15px' />
                <Text>{errors.password?.message} </Text>
              </FormErrorMessage>
              <AsyncButton
                formState={formState}
                type='submit'
                mt='33px'
                variant='login'
                onClick={handleSubmit(onSubmit)}
                backgroundColor='gold.1000'
                color='black'
                _hover={{ backgroundColor: 'darkGold.1000' }}
              >
                Link Accounts
              </AsyncButton>
            </form>
          </FormProvider>
        </FormControl>
      </Flex>
      <Link as={RouterLink} to='/login' textDecoration='underline' fontSize='14px'> Back to login </Link>
    </Flex>
  );
}
