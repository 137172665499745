import {
  Button, Heading, Stack, Text,
} from '@chakra-ui/react';
import { isAxiosError } from 'axios';
import { useMemo } from 'react';
import { When } from 'react-if';
import ReactJson from 'react-json-view';

interface IErrorPage {
  title?: string;
  error?: Error;
}

export default function ErrorPage({ title, error }: IErrorPage): React.ReactElement {
  const cleanedError = useMemo(() => {
    if (isAxiosError(error)) {
      return {
        code: error.code,
        message: error.message,
        url: error.config?.url,
        data: error.response?.data,
      };
    }
    return {
      ...error,
      message: error?.message,
      stack: error?.stack?.split('\n'),
    };
  }, [error]);

  return (
    <Stack spacing={10} direction='column' align='center'>
      <Heading as='h1' size='lg'>{title || error?.message || 'Something went wrong'}</Heading>
      <When condition={!!cleanedError}>
        <ReactJson
          name='ex'
          displayDataTypes={false}
          displayObjectSize={false}
          indentWidth={2}
          quotesOnKeys={false}
          enableClipboard={false}
          collapseStringsAfterLength={50}
          src={cleanedError!}
          collapsed={2}
          theme='embers'
        />
      </When>
      <Text fontFamily='monospace' opacity={0.5}>
        {window.location.toString()}
      </Text>
      <Button width={150} size='sm' onClick={() => window.location.reload()}>Reload</Button>
    </Stack>

  );
}
