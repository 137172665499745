import { IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react';
import { ReactElement } from 'react';

interface ButtonWithTooltipProps extends Omit<IconButtonProps, 'aria-label' | 'icon'> {
  title: string;
  icon: ReactElement;
}

export default function ButtonWithTooltip({
  title, icon, ...props
}: ButtonWithTooltipProps): React.ReactElement {
  return (
    <Tooltip label={title} placement='auto'>
      <IconButton
        icon={icon}
        aria-label={title}
        backgroundColor='transparent'
        variant='unstyled'
        {...props}
      />
    </Tooltip>
  );
}
