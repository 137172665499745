import {
  Flex,
  Input,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useAsync } from 'react-use';

import AsyncButton from './form/AsyncButton';
import { BasicModalHeader, ModalFrame } from './modal-frame/ModalFrame';
import QuerySuspense from './QuerySuspense';
import { transferServerApi } from 'api/transfersServerApi';
import { useApiQuery } from 'hooks/useApiQuery';

export interface ISmsVerifyModal {
  isOpen: boolean;
  onVerify: (pin: string, phoneNumber: string) => Promise<void>;
  onClose: () => void;
  transferId: number;
}

export default function SmsVerifyModal({
  isOpen, onClose, onVerify, transferId,
}: ISmsVerifyModal): React.ReactElement {
  const [pin, setPin] = useState<string>('');

  const smsRequestQuery = useApiQuery({
    queryKey: [`sms-${transferId}`],
    queryFn: () => transferServerApi.POST('/api-v2/transfers/{transferId}/sms', {
      params: {
        path: {
          transferId,
        },
      },
    }),
    enabled: false,
  });

  useAsync(async () => {
    if (isOpen) await smsRequestQuery.queryResult.refetch();
  }, [isOpen]);

  async function handleVerify(pinCode: string): Promise<void> {
    try {
      await onVerify(pinCode, smsRequestQuery.apiResult?.data?.phoneNumber!);
    } finally {
      setPin('');
    }
  }

  async function handleClose(): Promise<void> {
    setPin('');
    onClose();
  }

  return (
    <ModalFrame
      header={<BasicModalHeader title='SMS Verification' />}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <Flex
        direction='column'
        gap='8px'
      >
        <QuerySuspense queries={[smsRequestQuery.queryResult]}>
          <Text textAlign='center'>We&apos;ve sent a verification code to your number {smsRequestQuery.apiResult?.data?.phoneNumber}</Text>
          <Flex mt='2' mb='3' justifyContent='center'>
            <Input textAlign='center' variant='outline' width='200px' value={pin} onChange={(event): void => setPin(event.target.value)} />
          </Flex>
          <AsyncButton
            onClick={() => handleVerify(pin)}
          >Verify
          </AsyncButton>
        </QuerySuspense>
      </Flex>
    </ModalFrame>
  );
}
