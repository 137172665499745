import {
  Button, Flex, Img, Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import {
  Else, If, Then, When,
} from 'react-if';

import { ArchivesUploadModal } from './components/ArchivesUploadModal';
import UploadArchive from 'assets/icons/upload-archive.svg';
import { useTransfers } from 'contexts/transfer/TransferProvider';
import { TransferTaskList } from 'pages/transfers/upload-transfer-pages/components/TransferTaskList';

export function ArchivesUploadPage(): React.ReactElement {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const {
    transferTasks,
  } = useTransfers();

  return (
    <>
      <If condition={transferTasks.length > 0}>
        <Then>
          <Button size='lg' mb='50px' onClick={() => setIsUploadModalOpen(true)}>
            Upload New Archive
          </Button>
          <TransferTaskList />
        </Then>
        <Else>
          <Flex
            justifyContent='center'
          >
            <Flex
              direction='column'
              gap='18px'
              alignItems='center'
              justifyContent='center'
              maxW='580px'
            >
              <Flex alignItems='center' gap='10px'>
                <Img src={UploadArchive} w='25px' h='23px' />
                <Text as='span' fontSize='33px'>Archive Upload</Text>
              </Flex>
              <Text textAlign='center'>
                Initiate a new upload by searching and selecting a project and simply drag-and-drop the files for directly uploading to that chosen project
              </Text>
              <Button size='lg' onClick={() => setIsUploadModalOpen(true)}>
                Upload Archives
              </Button>
            </Flex>
          </Flex>
        </Else>
      </If>
      <When condition={isUploadModalOpen}>
        <ArchivesUploadModal
          isOpen={isUploadModalOpen}
          onClose={() => setIsUploadModalOpen(false)}
        />
      </When>
    </>
  );
}
