import {
  Flex, FlexProps, SystemStyleObject, Text, chakra,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { When } from 'react-if';

const defaultOverviewBeforeProps: SystemStyleObject = {
  content: '""',
  position: 'absolute',
  top: '0',
  left: '0',
  width: '8px',
  height: '100%',
  borderTopLeftRadius: '5px',
  borderBottomLeftRadius: '5px',
};

const defaultOverviewProps: FlexProps = {
  borderRadius: '5px',
  direction: 'column',
  padding: '20px',
  paddingLeft: '28px',
  position: 'relative',
  background: 'red.800',
  gap: '20px',
  minH: '148px',
  flexGrow: 1,
  _before: { ...defaultOverviewBeforeProps },
};

const defaultContainerProps: FlexProps = {
  pr: '50px',
  overflow: 'auto',
  gap: '100px',
  justifyContent: 'start',
  flex: 1,
};

export const OverviewDateFormat = 'MMMM d, yyyy';

export type OverviewProps = {
  header?: string,
  hideHeader?: boolean,
  before?: SystemStyleObject,
  inner?: FlexProps,
  className?: string,
} & FlexProps;

function Overview({
  header = 'Overview', hideHeader, before, inner, children, className,
}: OverviewProps & { children: ReactNode }): React.ReactElement {
  return (
    <Flex
      className={className}
      {...defaultOverviewProps}
      _before={{ ...defaultOverviewBeforeProps, ...before }}
    >
      <When condition={!hideHeader}>
        <Text
          fontFamily='Poppins'
          fontSize='18px'
        >
          {header}
        </Text>
      </When>
      <Flex
        {...defaultContainerProps}
        {...inner}
      >
        {children}
      </Flex>
    </Flex>
  );
}

export default chakra(Overview);
